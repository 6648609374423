import React, { Component } from "react";
import BigInfo from "../components/BigInfo";
import { GameContext } from "../contexts/gameContext";

class FirstPage extends Component {
  static contextType = GameContext;

  state = {
    buttons: [
      {
        text: "PLAY AS GUEST",
        onClick: () => {
          this.context.changePage("StartPage");
        },
        disabled: false,
      }
    ]
  };  

  userButtons = () => {
    let newButtons = [
      {
        text: "PLAY GAME",
        onClick: () => {
          this.context.changePage("StartPage");
        },
        disabled: false,
      }
    ];

    this.setState({buttons: newButtons})
  };

  guestButtons = () => {
    let newButtons = [
      {
        text: "PLAY AS GUEST",
        onClick: () => {
          this.context.changePage("StartPage");
        },
        disabled: false,
      }, 
      {
        text: "SIGN IN",
        onClick: () => {window.location = window.location + "profile"},
        disabled: false,
      }
    ];

    this.setState({buttons: newButtons})
  };

  checkButtons = () => {
    if(this.props.user)
      this.userButtons();
    else
      this.guestButtons();
  };

  componentDidMount() {
    this.checkButtons();
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user))
      this.checkButtons();
  }


  render() {
    return <BigInfo buttons={this.state.buttons} title="MAFIA GAME" />

  }
}

export default FirstPage;
