import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

class AllPlayersStatsRoleButton extends Component {
  state = {
    show: false,
  };

  onClick = () => {
    this.setState((prevState) => {
      return {
        show: !prevState.show,
      };
    });
  };
  render() {
    const { role } = this.props;
    const { show } = this.state;

    return (
      <Button
        size="small"
        variant="outlined"
        color={show ? (role !== "mafia" ? "primary" : "secondary") : "default"}
        onClick={this.onClick}
      >
        {show ? role : "Show"}
      </Button>
    );
  }
}

export default AllPlayersStatsRoleButton;
