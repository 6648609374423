import React, { Component } from "react";
import StarIcon from "@material-ui/icons/Star";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import MafiaIcon from "../../images/mafia.svg";
import WorkRoundedIcon from "@material-ui/icons/WorkRounded";
import PersonIcon from "@material-ui/icons/Person";
import Chip from "@material-ui/core/Chip";

class RoleChip extends Component {
  state = {};

  getRoleIcon = (role) => {
    switch (role) {
      case "mafia":
        return <WorkRoundedIcon />;

      case "sheriff":
        return <StarIcon />;

      case "doctor":
        return <LocalHospitalIcon />;

      case "farmer":
        return <PersonIcon />;
    }
  };

  render() {
    const { role } = this.props;
    return (
      <Chip
        color={role === "mafia" ? "secondary" : "primary"}
        size="medium"
        icon={this.getRoleIcon(role)}
        label={role.toUpperCase()}
      />
    );
  }
}

export default RoleChip;
