import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import { Button } from "@material-ui/core";
import { GameContext } from "../contexts/gameContext";
import { popupRouter } from "../utils/popupRouter";

class LeaveGame extends Component {
  static contextType = GameContext;

  state = {};

  tryLeave = () => {
    this.context.updateState({ popup: popupRouter("leaving", false) });
  };

  render() {
    return (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
      >
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={this.tryLeave}
          >
            LEAVE GAME
          </Button>
        </Grid>
      </Zoom>
    );
  }
}

export default LeaveGame;
