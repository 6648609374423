import { didTheyWin } from "./didTheyWin";

export const getBoolFacts = (player, status) => {
  return [
    { title: "SURVIVED", value: player.alive },
    {
      title: "WON GAME",
      value: didTheyWin(status, player.role),
    },
    { title: "ADMIN", value: player.admin },
  ];
};
