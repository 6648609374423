import WakeDoctor from "../../../audio/WakeDoctor.mp3";
import { cleanVotes } from "../cleanVotes";
import { getGameRef } from "../../../utils/firebase";
import { changeGameStatus } from "../../../utils/changeGameStatus";
import { playAudio } from "../../../utils/playAudio";

// Fake doctor wake up
export const statusFakeDoctor = (context) => {
  console.log("fakeDoctor() called");

  //const audio_wakeDoctor = new Audio(WakeDoctor);
  const playerName = context.player.name;
  const admin = context.game.playerList[playerName].admin;

  if (admin) {
    //audio_wakeDoctor.play();
    playAudio("wakeDoctor", context);

    cleanVotes(context.game.playerList);

    // Random time between 1 and 15 seconds before doctor is "done"
    const time = Math.random() * 15 * 1000;
    setTimeout(() => {
      changeGameStatus("doctorDone");
    }, time);
  }
};
