import {
  didPlayersSeeInfo,
  changePlayersPropInDB,
} from "../../../utils/commonFunctions";
import { changeGameStatus } from "../../../utils/changeGameStatus";

export const adminAssigned = (context) => {
  const playerList = Object.values(context.game.playerList);

  if (didPlayersSeeInfo(playerList)) {
    // Clean seenInfo on all players in db
    changePlayersPropInDB(context.game.playerList, "seenInfo", false);

    // Change game status
    changeGameStatus("night");
  }
};
