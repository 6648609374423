import { getGameRef } from "../../utils/firebase";
import { playAndPauseAllAudio } from "../../utils/playAudio";
import {assignRoles} from "./assignRoles"

export const  onStartGame = (context, roles) => {
    // Change game status
    getGameRef().child("status").set("started");

    // Play all sounds in order for sounds to be auto-played
    playAndPauseAllAudio(context.updateState);

    // Next step in game is to assign roles
    assignRoles(context.game.playerList, roles);
  };