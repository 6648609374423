import React, { Component } from "react";

// Pages
import LastPage from "../LastPage";
import GameLobby from "../GameLobby";
import StartPage from "../StartPage";
import FirstPage from "../FirstPage";
import InfoPage from "../InfoPage";
import PickPlayerPage from "../PickPlayerPage";
import GameOverPage from "../GameOverPage";
import LeaveGame from "../../components/LeaveGame";
import Popup from "../../components/Popup";
import AllPlayersStatsPage from "../AllPlayersStatsPage";
import IndividualPlayerStatsPage from "../IndividualPlayerStatsPage";

// Utils
import { kicked } from "../../utils/commonFunctions";
import { getGameRef } from "../../utils/firebase";
import { popupRouter } from "../../utils/popupRouter";

// MUI
import Grid from "@material-ui/core/Grid";

// MISC
import { GameContext, changeContextData } from "../../contexts/gameContext";
import { AppContext } from "../../contexts/appContext";
import CircularProgress from "@material-ui/core/CircularProgress";

// Router
import {
  Switch,
  Route,
} from "react-router-dom";

class GameEngine extends Component {
  static contextType = AppContext;

  constructor(props, context) {

    super(props, context);
    
    // Check local storage
    let page = localStorage.getItem("page");
    page = page ? page : "FirstPage";

    let code = localStorage.getItem("code");
    code = code ? code : "";

    let player = localStorage.getItem("player");
    player = player ? player : "";

    // If we are already in a game, then clear URL
    if (code) props.history.push("/");

    // Construct state
    this.state = {
      page: page,
      player: {
        name: player,
        uid: context.user.uid,
        avatar: context.user.photoURL,
      },
      game: {
        code: code,
      },
      startGameListen: this.startGameListen,
      updateState: this.updateState,
      changePage: this.changePage,
      transition: { in: true, timeout: 300 },
      connected: false,
      popup: {},
      allowAudio: false,
      allowAudioPopupNotShown: true,
    };
  }

  componentDidMount() {
    // If we are in a game, then listen for updates
    if (this.state.game.code) {
      getGameRef().on("value", this.firebaseListener);
    }
  }

  componentDidUpdate() {
    const { connected, game, player, allowAudio, allowAudioPopupNotShown } = this.state;
    const {status} = game;

    if (connected && !kicked(game.playerList, player.name)) {
      const currentPlayer = game.playerList[this.state.player.name];

      // If we are admin and haven't "allowed audio"
      if (currentPlayer.admin && currentPlayer.alive && !allowAudio && allowAudioPopupNotShown  && status !== "open") {
        const popup = popupRouter("reload", false, "", this.state);
        if (popup) this.setState({ popup: popup, allowAudioPopupNotShown: false });
      }

      // Popup
      const step = game.playerList[player.name].popup;
      const popup = popupRouter(step, true, player.name);
      if (popup) this.setState({ popup: popup });
    }

    // Update test global context
    changeContextData(this.state);

    // Make sure uid and avatar is updated in state.player
    if (this.context.user.uid !== this.state.player.uid) {
      this.setState({
        player: Object.assign(this.state.player, {
          uid: this.context.user.uid,
          avatar: this.context.user.photoURL,
        }),
      });
    }
  }

  startGameListen = (code) => {
    // Save code in local storage
    localStorage.setItem("code", code);

    // Listens for changes in game in db
    getGameRef().on("value", this.firebaseListener);
  };

  firebaseListener = (snap) => {
    // Make sure snapshot (game) even exists
    if (snap.exists()) {
      this.setState({
        game: snap.val(),
        connected: true,
      });

      console.log("Received update from firebase!");
    }
    // If it doesn't then clear local storage and reload
    else {
      localStorage.clear();
      window.location.reload();
    }
  };

  updateState = (state, callback) => {
    this.setState(state, callback);
  };

  changePage = (page) => {
    const timeout = this.state.transition.timeout;
    // Save page in local storage
    localStorage.setItem("page", page);

    const change = (page) => {
      setTimeout(
        () =>
          this.setState({
            transition: { in: true, timeout: timeout },
            page: page,
          }),
        timeout
      );
    };

    this.setState({ transition: { in: false, timeout: timeout } }, () =>
      change(page)
    );
  };

  gamePages = () => {
    // Check if we are in a game, and if so make sure we are connected to db
    if (!this.state.game.code || this.state.connected) {
      switch (this.state.page) {
        case "FirstPage":
          return <FirstPage user={this.context.user}/>;

        case "GameLobby":
          return <GameLobby />;

        case "InfoPage":
          return <InfoPage />;

        case "PickPlayerPage":
          return <PickPlayerPage />;

        case "LastPage":
          return <LastPage />;

        case "StartPage":
          return <StartPage />;

        case "GameOverPage":
          return <GameOverPage />;

        case "AllPlayersStatsPage":
          return <AllPlayersStatsPage />;

        case "IndividualPlayerStatsPage":
          return <IndividualPlayerStatsPage player={this.state.statsPlayer} />;

        default:
          return this.setState({ page: "FirstPage" });
      }
    }
    // Else just show loader
    else {
      return (
        <Grid item>
          {/* <img src={LoadSvg} alt="Load SVG" /> */}
          <CircularProgress />
        </Grid>
      );
    }
  };

  render() {
    const { game, connected } = this.state;

    return (
      <GameContext.Provider value={this.state}>
        {/* Game component */}
        <Switch>
          <Route path="/join/:code" component={StartPage} />
          <Route path="/">{this.gamePages()}</Route>
        </Switch>

        {/* Leave game component */}
        {game.code && connected ? <LeaveGame /> : null}

        {/* Popup component */}
        {this.state.popup.active ? <Popup /> : null}
      </GameContext.Provider>
    );
  }
}

export default GameEngine;
