import { displaySheriffChose } from "../displayFunctions/displaySheriffChose";
import { adminSheriffDone } from "../adminFunctions/adminSheriffDone";

export const statusSheriffDone = (context, setState) => {
  console.log("Sheriff is done!");

  const playerName = context.player.name;
  const player = context.game.playerList[playerName];
  const role = player.role;
  const seenInfo = player.seenInfo;
  const admin = player.admin;

  if (role === "sheriff" && !seenInfo) displaySheriffChose(context, setState);

  if (admin) adminSheriffDone(context);
};
