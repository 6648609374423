export const goToStep = (step, context, updateState) => {
    const timeout = context.transition.timeout;
    context.updateState(
      { transition: { in: false, timeout: timeout } },
      () => {
        setTimeout(() => {
          updateState({ step: step });
          context.updateState({
            transition: { in: true, timeout: timeout },
          });
        }, timeout);
      }
    );
  };