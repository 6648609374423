import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import LoadSvg from "../images/loading.svg";
import { GameContext } from "../contexts/gameContext";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class InfoBox extends Component {
  static contextType = GameContext;
  render() {
    const text1 = (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
      >
        <Grid item>
          <Typography variant="h5">{this.props.textObject.text1}</Typography>
        </Grid>
      </Zoom>
    );

    const text2 = (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
        unmountOnExit
      >
        <Grid item>
          <Typography color={this.props.textObject.color} variant="h4">
            {this.props.textObject.text2}
          </Typography>
        </Grid>
      </Zoom>
    );

    const text3 = (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
      >
        <Grid item>
          <Typography variant="h5">{this.props.textObject.text3}</Typography>
        </Grid>
      </Zoom>
    );

    const loader = (
      <Grid item>
        {/* <img src={LoadSvg} alt="Load SVG" /> */}
        <CircularProgress />
      </Grid>
    );

    const button = (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
      >
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={this.props.textObject.onClick}
          >
            OK
          </Button>
        </Grid>
      </Zoom>
    );

    return (
      <Grid container direction="column" alignItems="center" spacing={4}>
        {this.props.textObject.text1 ? text1 : null}

        {this.props.textObject.showLoader ? loader : null}

        {this.props.textObject.text2 ? text2 : null}

        {this.props.textObject.text3 ? text3 : null}

        {this.props.textObject.showButton ? button : null}
      </Grid>
    );
  }
}
