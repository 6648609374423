import * as firebase from "firebase";

export const listenForAuthStateChange = (setState) => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          console.log(user);
          setState({ user: user });
        } else {
          console.log("No user signed in.");
          setState({ user: "" });
        }
      });
};