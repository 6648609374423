const { stepFour } = require("./stepFour");
const { stepOne } = require("./stepOne");
const { stepThree } = require("./stepThree");
const { stepTwo } = require("./stepTwo");

export const stepSwitch = (context, updateState, state, routerHistory) => {
    switch(state.step) {
        case "One":
            stepOne(context, updateState);
            break;

            case "Two":
            stepTwo(state, updateState, context);
            break;

            case "Three":
            stepThree(state, updateState, context);
            break;

            case "Four":
            stepFour(state, updateState, context, routerHistory);
            break;

    };
  };