import { createInfoBoxObject } from "../../../utils/createObject";
import { increaseUserStat } from "../../../utils/firebase";
import { seenInfo } from "../seenInfo";

export const displayAssignedRole = (context, setState) => {
  const playerName = context.player.name;
  const player = context.game.playerList[playerName];
  const role = player.role;

  let textObject = createInfoBoxObject();
  textObject.text1 = "You are a";
  textObject.text2 = role;
  textObject.color = role === "mafia" ? "secondary" : "primary";
  textObject.showButton = true;
  textObject.onClick = () => seenInfo(false, context, setState);

  setState({ textObject: textObject });
};
