import React, { Component } from "react";
import { Button } from "@material-ui/core";

class ChangePlayerButton extends Component {
  state = {};
  render() {
    const { player, changePlayer } = this.props;

    // Make sure player is not undefined
    if (typeof player === "undefined") return null;
    return (
      <Button
        variant="outlined"
        size="small"
        color={player.role === "mafia" ? "secondary" : "primary"}
        onClick={() => changePlayer(player.name)}
      >
        {player.name}
      </Button>
    );
  }
}

export default ChangePlayerButton;
