import React, { Component } from "react";
import BigInfo from "../components/BigInfo";
import { GameContext } from "../contexts/gameContext";

class GameOverPage extends Component {
  static contextType = GameContext;

  state = {};

  buttons = [
    {
      text: "SEE STATS",
      onClick: () => {
        this.context.changePage("AllPlayersStatsPage");
      },
      disabled: false,
    },
  ];

  render() {
    let text1 = "You died!";

    const status = this.context.game.status;

    if (status === "cityWon") text1 = "The city won!";
    if (status === "mafiaWon") text1 = "The mafia won!";

    return <BigInfo buttons={this.buttons} title="GAME OVER" text1={text1} />;
  }
}

export default GameOverPage;
