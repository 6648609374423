import React, { Component } from 'react';

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import HelpIcon from "@material-ui/icons/Help";
import PersonIcon from "@material-ui/icons/Person";
import {Link} from "react-router-dom";

class MyBottomNavigation extends Component {
    render() { 

        const {dir, updateState, user} = this.props;

        return ( 
            <BottomNavigation
              value={dir}
              onChange={(event, newValue) => {
                updateState({ bottomNavDir: newValue });
              }}
              showLabels
            >
              <BottomNavigationAction
                to="/"
                component={Link}
                label="GAME"
                value="game"
                icon={<SportsEsportsIcon />}
              />
              <BottomNavigationAction
                to="/guide"
                component={Link}
                label="GUIDE"
                value="guide"
                icon={<HelpIcon />}
              />
              <BottomNavigationAction
                to="/profile"
                component={Link}
                label={user ? "PROFILE" : "SIGN IN"}
                value="profile"
                icon={<PersonIcon />}
              />
            </BottomNavigation>
         );
    }
}
 
export default MyBottomNavigation;