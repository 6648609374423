import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

class BoolFacts extends Component {
  state = {};

  boolToIcon = (input) => {
    return input ? (
      <CheckCircleIcon color="primary" />
    ) : (
      <CancelIcon color="secondary" />
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.boolFacts.map((fact, i) => {
          return (
            <TableRow key={i}>
              <TableCell>{fact.title}</TableCell>
              <TableCell align="center">
                {this.boolToIcon(fact.value)}
              </TableCell>
            </TableRow>
          );
        })}
      </React.Fragment>
    );
  }
}

export default BoolFacts;
