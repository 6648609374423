import { createInfoBoxObject } from "../../../utils/createObject";
import { seenInfo } from "../seenInfo";

export const displaySheriffChose = (context, setState) => {
  const chosen = context.game.sheriffChose;
  const role = context.game.playerList[chosen].role;

  let textObject = createInfoBoxObject();
  textObject.text1 = chosen + " is";
  textObject.text2 = role === "mafia" ? "EVIL" : "GOOD";
  textObject.color = role === "mafia" ? "secondary" : "primary";
  textObject.showButton = true;
  textObject.onClick = () => seenInfo(true, context, setState);

  setState({ textObject: textObject });
};
