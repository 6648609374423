import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { GameContext } from "../contexts/gameContext";
import PlayerList from "../components/PlayerList";

export default class PickPlayer extends Component {
  static contextType = GameContext;

  disableFunction = (player) =>{
    const status = this.context.game.status;
    const oldDoctor =
      status === "doctor" ? this.context.game.oldDoctorChose : "";
    return oldDoctor === player.name;
  };

  colorFunction = (player) => {
    const playerName = this.context.player.name;
    const votesFor = this.context.game.playerList[playerName].votesFor;
    const {buttonColor, clickedColor} = this.props.pickObject;

    return player.name === votesFor ? clickedColor : buttonColor
  };

  render() {
    let i = 0;
    const {
      title,
      infoText,
      buttonColor,
      clickedColor,
      players,
      onClick,
    } = this.props.pickObject;

    const playerName = this.context.player.name;
    const votesFor = this.context.game.playerList[playerName].votesFor;
    const status = this.context.game.status;
    const oldDoctor =
      status === "doctor" ? this.context.game.oldDoctorChose : "";
    return (
      <Grid container direction="column" alignItems="center" spacing={4}>
        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
          style={{ transitionDelay: "" + i++ * 200 + "ms" }}
        >
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
        </Zoom>

        <Grid item>
          <Typography variant="h5">{infoText}</Typography>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          <PlayerList players={players} colorFunction={this.colorFunction} onClick={onClick} disableFunction={this.disableFunction} />
        </Grid>
      </Grid>
    );
  }
}
