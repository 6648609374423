import { cleanVotes } from "../cleanVotes";
import { didIDie } from "../didIDie";
import { gameOver } from "../gameOver";
import { createInfoBoxObject } from "../../../utils/createObject";
import { seenInfo } from "../seenInfo";

export const statusDay = (context, setState) => {
  const me = context.player.name;
  const { admin } = context.game.playerList[me];

  // Clean votes
  if (admin) cleanVotes(context.game.playerList);

  // Make sure we didn't die
  if (didIDie(context)) gameOver(context);
  else {
    let textObject = createInfoBoxObject();
    //textObject.text1 = "";
    textObject.text2 = "DISCUSSION TIME";
    textObject.color = "primary";
    textObject.text3 =
      "Who do you think is a mafia? Discuss with your friends. When ready to vote for a player, press ok.";
    textObject.showButton = true;
    textObject.onClick = () => seenInfo(false, context, setState);

    setState({ textObject: textObject });
  }
};
