import { createLabelInputButtonArr } from "../../../utils/createObject";
import { onCreateGame } from "../onCreateGame";

export const stepTwo = (state, updateState, context) => {
    let layoutArr = createLabelInputButtonArr(1);
    layoutArr[0].label = "What's your name?";
    layoutArr[0].placeholder = "Write your name here";
    layoutArr[0].buttonText = "CREATE GAME";
    layoutArr[0].buttonSize = "large";
    layoutArr[0].value = state.name;
    layoutArr[0].inputName = "name";
    layoutArr[0].error = state.nameError;
    layoutArr[0].onClick = () => onCreateGame(state, context, updateState);

    updateState({ layoutArr: layoutArr });
  };