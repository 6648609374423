import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import LabelInputButton from "../../components/LabelInputButton";
import { GameContext } from "../../contexts/gameContext";
import {
  createPlayerObject,
  createLabelInputButtonArr,
  createGameObject,
} from "../../utils/createObject";
import { gameListRef } from "../../utils/firebase";
import { validatePlayerName } from "../../utils/commonFunctions";
import { firebaseListener } from "./firebaseListener";
import {stepSwitch} from "./stepSwitch/stepSwitch";
import { goBack } from "./stepSwitch/goBack";
import { onChange } from "./onChange";
import { onBlur } from "./onBlur";


class StartPage extends Component {
  static contextType = GameContext;

  constructor(props) {
    super(props);

    // Link join
    let code = "";
    let step = "One";

    // Check if we are here via invite link
    if (typeof this.props.match !== "undefined") {
      code = this.props.match.params.code.toUpperCase();
      step = "Three";
    }

    this.state = {
      name: "",
      code: code,
      nameError: "",
      codeError: "",
      gameList: {},
      step: step,
      layoutArr: [],
    };
  }

  componentDidMount() {
    // Listens for changes in game in db
    gameListRef.on("value", (snap) => firebaseListener(snap, this.updateState));

    stepSwitch(this.context, this.updateState, this.state, this.props.history);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if there is a new gameList
    const newGameList = JSON.stringify(this.state.gameList) !== JSON.stringify(prevState.gameList);

    // If certain things have changed in state, then call on the stepSwitch
    if (prevState.step !== this.state.step || prevState.name !== this.state.name || prevState.code !== this.state.code || newGameList)
      stepSwitch(this.context, this.updateState, this.state, this.props.history);
  }

  componentWillUnmount() {
    // Stop listening for changes in game in db
    gameListRef.off("value", (snap) => firebaseListener(snap, this.updateState));
  }

  updateState = (state) => {
    this.setState(state);
  };

  render() {
    return (
      <Grid container item direction="column" alignItems="center" spacing={8}>
        {this.state.layoutArr.map((layout, i) => {
          return (
            <LabelInputButton
              key={i}
              layout={layout}
              name={this.state.name}
              code={this.state.code}
              nameError={this.state.nameError}
              codeError={this.state.codeError}
              onChange={(e) => onChange(e, this.updateState)}
              onBlur={(e) => onBlur(e, this.updateState)}
            />
          );
        })}

        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
        >
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="default"
              onClick={() => goBack(this.state, this.context, this.updateState, this.props.history)}
            >
              BACK
            </Button>
          </Grid>
        </Zoom>
      </Grid>
    );
  }
}

export default StartPage;
