import React, { Component } from "react";
import "./App.css";

// Pages
import GameEngine from "./pages/GameEngine";
import HowToPlayPage from "./pages/HowToPlayPage";
import UserProfilePage from "./pages/UserProfilePage";

// Utils
import { AppContext } from "./contexts/appContext";

// MUI
import Grid from "@material-ui/core/Grid";
import { ThemeProvider } from "@material-ui/styles";
import {mainTheme} from "./themes/mainTheme";

// Components
import MyBottomNavigation from "./components/MyBottomNavigation";
import FacebookBrowserWarning from "./components/FacebookBrowserWarning";


// Router
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { getBottomNavDir } from "./functions/getBottomNavDir";
import { isFacebookApp } from "./functions/isFacebookApp";
import { listenForAuthStateChange } from "./functions/listenForAuthStateChange";

class App extends Component {
  constructor() {
    super();

    // Get bottomNav
    let bottomNavDir = getBottomNavDir(window.location.href);

    this.state = {
      transition: { in: true, timeout: 300 },
      facebook: isFacebookApp(),
      signedIn: false,
      bottomNavDir: bottomNavDir,
      user: "",
    };
  }

  updateState = (state) => {
    this.setState(state);
  };

  componentDidMount() {
    // Start listening for Firebase log ins/outs
    listenForAuthStateChange(this.updateState);
  }

  render() {
    const { game, connected, bottomNavDir, user } = this.state;

    // Make sure a Facebook/Instagram broswer isn't being used
    return !this.state.facebook ? (
      <Router>
        <AppContext.Provider value={this.state}>
          <ThemeProvider theme={mainTheme}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={4}
              style={{ minHeight: "90vh", textAlign: "center" }}
            >
              <Switch>
                <Route path="/guide">
                  <HowToPlayPage />
                </Route>
                <Route path="/profile">
                  <UserProfilePage />
                </Route>
                <Route path="/" component={GameEngine} />
              </Switch>
            </Grid>
            <MyBottomNavigation user={user} dir={bottomNavDir} updateState={this.updateState} />
          </ThemeProvider>
        </AppContext.Provider>
      </Router>
    ) : (
      <FacebookBrowserWarning/>
    );
  }
}

export default App;
