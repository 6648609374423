import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import Slider from "@material-ui/core/Slider";
import Zoom from "@material-ui/core/Zoom";
import { GameContext } from "../contexts/gameContext";

class RoleInput extends Component {
  static contextType = GameContext;

  state = {
    numbers: [
      "None",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
    ],
  };

  // Creates the menu items in the mafia select
  getMenuItems = (role) => {
    let menuItems = [];

    const players = Object.keys(this.context.game.playerList).length;
    const roles = this.props.roles;
    const min = role === "mafia" ? 1 : 0;
    const mafiaMax = Math.ceil(players / 2) - 1;
    let max = 0;
    let otherRoles = 0;

    // Add up number of other roles
    Object.keys(this.props.roles).map((mapRole) => {
      otherRoles += mapRole === role ? 0 : roles[mapRole];
    });

    // The max number to pick in select
    max = players - otherRoles;

    // If the role is mafia, make sure it doesn't go above the mafia max limit
    max = role === "mafia" && max > mafiaMax ? mafiaMax : max;

    // Make sure max is not less than min
    max = min > max ? min : max;

    // Create menu items
    for (let i = min; i <= max; i++) {
      menuItems.push({ value: i, text: this.state.numbers[i] });
    }

    return menuItems;
  };

  render() {
    const { role, handleChange, roles } = this.props;
    const title = role.replace(/^./, role[0].toUpperCase());
    const menuItems = this.getMenuItems(role);

    return (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
      >
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel htmlFor={role}>{title}</InputLabel>
            <Select
              style={{ width: "200px" }}
              label={role}
              onChange={handleChange}
              inputProps={{ name: role, id: role }}
              value={roles[role]}
            >
              {this.getMenuItems(role).map((menuItem) => {
                return (
                  <MenuItem key={menuItem.value} value={menuItem.value}>
                    {menuItem.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Zoom>
    );
  }
}

export default RoleInput;
