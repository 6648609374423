import { createInfoBoxObject } from "../../../utils/createObject";

export const displayWait = (setState) => {
  console.log("displayWait() called");

  let textObject = createInfoBoxObject();
  textObject.text1 = "Waiting for players";
  textObject.showLoader = true;

  setState({ textObject: textObject });
};
