import React, { Component } from 'react';

class FacebookBroswerWarning extends Component {
    render() { 
        return ( <h1>
            You are using a Facebook/Instagram in-app browser. Please use your
            normal broswer.
          </h1> );
    }
}
 
export default FacebookBroswerWarning;