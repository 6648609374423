import { getGameRef } from "../../utils/firebase";

export const assignRoles = (playerList, roles) => {
    let farmers = Object.values(playerList);
    let chosen = 0;
    let newPlayerList = Object.assign(playerList, {});

    // Assign roles
    Object.keys(roles).map((role) => {
      for (let i = 0; i < roles[role]; i++) {
        chosen = Math.floor(Math.random() * farmers.length);
        const player = farmers[chosen];
        newPlayerList[player.name].role = role;
        farmers.splice(chosen, 1);
      }
    });

    // Update firebase with roles object
    getGameRef().child("roles").set(roles);

    // Update firebase with new playerList
    getGameRef().child("playerList").set(newPlayerList);

    // Update game status
    getGameRef().child("status").set("assigned");
  };