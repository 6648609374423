import { generateGameCode } from "./commonFunctions";

export const createPopupObject = () => {
  return {
    active: true,
    title: "",
    message: "",
    question: false,
    noCB: () => {},
    yesCB: () => {},
  };
};

export const createInfoBoxObject = () => {
  return {
    text1: "",
    color: "primary",
    text2: "",
    text3: "",
    showButton: false,
    showLoader: false,
    onClick: () => {},
  };
};

export const createPlayerObject = (name, admin, uid, avatar) => {
  let myUid = uid ? uid : "";
  let myAvatar = avatar ? avatar : "";

  return {
    name: name,
    role: "farmer",
    alive: true,
    admin: admin,
    seenInfo: false,
    votesFor: "",
    votes: 0,
    popup: "",
    votedFor: {},
    uid: myUid,
    avatar: myAvatar,
  };
};

export const createLabelInputButtonArr = (numItems) => {
  let layoutArr = [];

  for (let i = 0; i < numItems; i++) {
    layoutArr[i] = {
      label: "",
      placeholder: "",
      buttonText: "",
      buttonSize: "large",
      buttonColor: "primary",
      value: "",
      inputName: "",
      onClick: () => {},
      error: "",
    };
  }

  return layoutArr;
};

export const createPickPlayerObject = () => {
  return {
    title: "",
    infoText: "",
    buttonColor: "primary",
    clickedColor: "",
    players: [],
    noneButton: false,
    onClick: () => {},
  };
};

export const createGameObject = (playerObj, gameList) => {
  const gameObj = {
    code: generateGameCode(gameList),
    status: "open",
    playerList: { [playerObj.name]: playerObj },
    mafiaChose: "",
    doctorChose: "",
    oldDoctorChose: "",
    sheriffChose: "",
    cityChose: "",
    votesToKillNone: 0,
    roles: { mafia: 0, sheriff: 0, doctor: 0 },
    voteMessage: "",
  };

  return gameObj;
};
