import { changePlayerPropInDB } from "../../utils/commonFunctions";
import { displayNight } from "./displayFunctions/displayNight";
import { displayWait } from "./displayFunctions/displayWait";

export const seenInfo = (night, context, setState) => {
  const { player } = context;

  // Update seenInfo in firebase
  changePlayerPropInDB(player.name, "seenInfo", true);

  // If night, then go back to night mode
  if (night) displayNight(setState);
  // Else, just wait
  else displayWait(setState);
};
