import wakeCity from "../audio/WakeCity.mp3";
import sleepCity from "../audio/SleepCity.mp3";
import wakeMafia from "../audio/WakeMafia.mp3";
import sleepMafia from "../audio/SleepMafia.mp3";
import wakeDoctor from "../audio/WakeDoctor.mp3";
import sleepDoctor from "../audio/SleepDoctor.mp3";
import wakeSheriff from "../audio/WakeSheriff.mp3";
import sleepSheriff from "../audio/SleepSheriff.mp3";

export const allAudioFiles = {
  wakeMafia: new Audio(wakeMafia),
  wakeSheriff: new Audio(wakeSheriff),
  wakeDoctor: new Audio(wakeDoctor),
  wakeCity: new Audio(wakeCity),
  sleepCity: new Audio(sleepCity),
  sleepDoctor: new Audio(sleepDoctor),
  sleepSheriff: new Audio(sleepSheriff),
  sleepMafia: new Audio(sleepMafia),
};

export const playAndPauseAllAudio = (setState) => {
  Object.values(allAudioFiles).forEach((audio) => {
    audio.play();
    audio.pause();
  });
  setState({allowAudio: true});
};

export const playAudio = (audio, context) => {
  if(context.allowAudio) allAudioFiles[audio].play();
};
