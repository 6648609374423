import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as firebase from "firebase";
import Zoom from "@material-ui/core/Zoom";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import SignInWithGoogleButton from "./SignInWithGoogleButton";
import SignInWithFacebookButton from "./SignInWithFacebookButton";
import CircularProgress from "@material-ui/core/CircularProgress";

class SignInOrUp extends Component {
  state = {
    signIn: true,
    email: "",
    password: "",
    confirmPassword: "",
    emailError: "",
    passError: "",
    confirmError: "",
    showPassword: false,
    showConfirm: false,
    providerError: "",
    loading: true,
  };

  componentDidMount() {
    // Component has loading screen as default. If we are returned a null user or error, then we remove the loading screen
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        if (result.user === null) {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ providerError: error.message, loading: false });
      });
  }

  componentDidUpdate() {
    // If we get a user from the App component, then remove loading screen
    if (this.props.user) this.setState({ loading: false });
  }

  // When email or password inputs are changed
  onChange = (event) => {
    const { name, value } = event.target;
    const error = this.getErrorName(name);
    this.setState({ [event.target.name]: event.target.value, [error]: false });
  };

  getErrorName = (inputName) => {
    switch (inputName) {
      case "email":
        return "emailError";

      case "password":
        return "passError";

      case "confirmPassword":
        return "confirmError";
    }
  };

  // When sign in button is pressed
  onSubmit = () => {
    // If we are trying to sign in
    if (this.state.signIn) {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .catch(this.errorHandler);
    }
    // If we are getting registered
    else {
      // Make sure pass and confirmPass are the same
      if (this.state.password === this.state.confirmPassword) {
        // Create user
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.state.email, this.state.password)
          .catch(this.errorHandler);
      }
      // If pass and confirmPass doesn't match
      else {
        this.setState({ confirmError: "Password doesn't match" });
      }
    }
  };

  // Catches errors and determines where to display them
  errorHandler = (error) => {
    console.log(error.code);
    console.log(error.message);

    if (error.code.search("email") !== -1) {
      this.setState({ emailError: error.message });
    } else {
      this.setState({ passError: error.message });
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleClickShowConfirm = () => {
    this.setState({ showConfirm: !this.state.showConfirm });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleLinkClick = (event) => {
    event.preventDefault();
    this.setState({ signIn: !this.state.signIn });
  };

  render() {
    const {
      signIn,
      email,
      password,
      confirmPassword,
      emailError,
      passError,
      confirmError,
      showPassword,
      showConfirm,
      providerError,
    } = this.state;

    return !this.state.loading ? (
      <React.Fragment>
        <Grid item>
          <Typography variant="h4">
            SIGN {this.state.signIn ? "IN" : "UP"}
          </Typography>
        </Grid>

        <Grid item>
          <FormControl style={{ width: "320px" }}>
            <Input
              placeholder="Email"
              onChange={this.onChange}
              name="email"
              value={email}
              error={emailError ? true : false}
              autoComplete="off"
            />
            <FormHelperText error>{emailError}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl style={{ width: "320px" }}>
            <Input
              placeholder="Password"
              onChange={this.onChange}
              name="password"
              value={password}
              error={passError ? true : false}
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>{passError}</FormHelperText>
          </FormControl>
        </Grid>

        {signIn ? null : (
          <Grid item>
            <FormControl style={{ width: "320px" }}>
              <Input
                placeholder="Confirm password"
                onChange={this.onChange}
                name="confirmPassword"
                value={confirmPassword}
                error={confirmError ? true : false}
                type={showConfirm ? "text" : "password"}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirm}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {showConfirm ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText error>{confirmError}</FormHelperText>
            </FormControl>
          </Grid>
        )}

        <Grid item>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={this.onSubmit}
          >
            SIGN {signIn ? "IN" : "UP"}
          </Button>
        </Grid>

        {this.state.signIn ? (
          <Grid item>
            <Typography variant="body1">
              Don't have an account?{" "}
              <Link onClick={this.handleLinkClick}>Sign up</Link>
            </Typography>
          </Grid>
        ) : (
          <Grid item>
            <Typography variant="body1">
              Already have an account?{" "}
              <Link onClick={this.handleLinkClick}>Sign in</Link>
            </Typography>
          </Grid>
        )}

        <SignInWithGoogleButton />

        <SignInWithFacebookButton />

        <Grid item>
          <FormHelperText error>{providerError}</FormHelperText>
        </Grid>
      </React.Fragment>
    ) : (
      <CircularProgress />
    );
  }
}

export default SignInOrUp;
