import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Zoom from "@material-ui/core/Zoom";

import Button from "@material-ui/core/Button";
import { GameContext } from "../../contexts/gameContext";

export default class LabelInputButton extends Component {
  static contextType = GameContext;

  onKeyUp = (e) => {
    // If some presses enter in the input field, call onClick funtion
    if(e.keyCode === 13) this.props.layout.onClick();
  };

  render() {
    const slideIn = this.context.transition.in;
    const { timeout } = this.context.transition;
    const {
      label,
      placeholder,
      buttonText,
      buttonSize,
      buttonColor,
      inputName,
      onClick,
    } = this.props.layout;

    const { onBlur, onChange } = this.props;

    const value = this.props[inputName];
    const error = this.props[inputName + "Error"];

    let labelElement = (
      <Zoom in={slideIn} timeout={timeout} mountOnEnter={false}>
        <Grid item>
          <Typography variant="h6">{label}</Typography>
        </Grid>
      </Zoom>
    );

    let inputElement = (
      <Zoom in={slideIn} timeout={timeout} mountOnEnter={false}>
        <Grid item>
          <FormControl>
            <Input
              id="component-error"
              placeholder={placeholder}
              onChange={onChange}
              name={inputName}
              value={value}
              onBlur={onBlur}
              onKeyUp={this.onKeyUp}
              error={error ? true : false}
              autoComplete="off"
            />
            <FormHelperText error>{error}</FormHelperText>
          </FormControl>
        </Grid>
      </Zoom>
    );

    let buttonElement = (
      <Zoom in={slideIn} timeout={timeout} mountOnEnter={false}>
        <Grid item>
          <Button
            variant="contained"
            size={buttonSize}
            color={buttonColor}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Grid>
      </Zoom>
    );

    return (
      <Grid container item direction="column" alignItems="center" spacing={3}>
        {label ? labelElement : null}

        {inputName ? inputElement : null}

        {buttonText ? buttonElement : null}
      </Grid>
    );
  }
}
