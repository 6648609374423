import { createLabelInputButtonArr } from "../../../utils/createObject";
import { checkGameCode } from "../checkGameCode";

export const stepThree = (state, updateState, context) => {
    let layoutArr = createLabelInputButtonArr(1);

    layoutArr[0].label = "Ask your friend for game code";
    layoutArr[0].placeholder = "Write game code here";
    layoutArr[0].value = state.code;
    layoutArr[0].inputName = "code";
    layoutArr[0].error = state.codeError;
    layoutArr[0].buttonText = "NEXT";
    layoutArr[0].onClick = () => checkGameCode(state, updateState, context)

    updateState({ layoutArr: layoutArr });
  };