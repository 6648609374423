import { validatePlayerName } from "../../utils/commonFunctions";
import { createGameObject, createPlayerObject } from "../../utils/createObject";
import { gameListRef, increaseUserStat } from "../../utils/firebase";

export const onCreateGame = (state, context, updateState) => {
    const name = state.name;
    
    // Validate player name
    const valObj = validatePlayerName(name);
    if (!valObj.nameOk) {
      updateState({ nameError: valObj.error });
      return;
    }
    // If there were no name errors

    // Create player object
    let player = createPlayerObject(
      name,
      true,
      context.player.uid,
      context.player.avatar
    );

    // Add game to gameList in db
    const game = createGameObject(player, Object.keys(state.gameList));
    gameListRef.child(game.code).set(game);

    console.log("Game", game.code, "was added.");

    // Update player in main state and local storage
    context.updateState({ player: { name: name } });
    localStorage.setItem("player", name);

    // Start listen for game changes in db
    context.startGameListen(game.code);

    // If signed in, increase user stat
    if(typeof context.player.uid !== "undefined") increaseUserStat(context.player.uid, "numCreatedGames");

    // Move on
    context.changePage("GameLobby");
  };