import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { GameContext } from "../contexts/gameContext";
import AllPlayersStatsRoleButton from "../components/AllPlayersStatsRoleButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";

class AllPlayersStatsPage extends Component {
  static contextType = GameContext;

  onClick = (player) => {
    localStorage.setItem("statsPlayer", player.name);
    this.context.changePage("IndividualPlayerStatsPage");
  };

  state = {};

  createPlayerTableRows = () => {
    const playerList = Object.values(this.context.game.playerList);

    return playerList.map((player) => (
      <TableRow key={player.name}>
        <TableCell component="th" scope="row">
          {player.name}
        </TableCell>
        <TableCell>
          <AllPlayersStatsRoleButton role={player.role} />
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => this.onClick(player)}
          >
            MORE
          </Button>
        </TableCell>
      </TableRow>
    ));
  };
  render() {
    return (
      <React.Fragment>
        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
        >
          <Grid item>
            <h1>STATS</h1>
          </Grid>
        </Zoom>

        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
        >
          <Grid item>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>NAME</TableCell>
                  <TableCell>ROLE</TableCell>
                  <TableCell>DETAILS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.createPlayerTableRows()}</TableBody>
            </Table>
          </Grid>
        </Zoom>
      </React.Fragment>
    );
  }
}

export default AllPlayersStatsPage;
