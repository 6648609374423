import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ChangePlayerButton from "./ChangePlayerButton";
import { GameContext } from "../../contexts/gameContext";

class VoteStats extends Component {
  static contextType = GameContext;

  state = {};

  render() {
    return (
      <React.Fragment>
        {Object.values(this.props.voteStats).map((stat, i) => {
          if (stat.name !== "")
            return (
              <TableRow key={i}>
                <TableCell>{stat.text.toUpperCase()}</TableCell>
                <TableCell align="center">
                  <ChangePlayerButton
                    player={this.context.game.playerList[stat.name]}
                    changePlayer={this.props.changePlayer}
                  />
                </TableCell>
              </TableRow>
            );
        })}
      </React.Fragment>
    );
  }
}

export default VoteStats;
