import { createPopupObject } from "../../utils/createObject";
import { getGameRef } from "../../utils/firebase";

export const kickPlayer = (playerName, context) => {

  //Make sure we don't kick ourselves
  if(context.player.name === playerName){
    return;
  }

    // Create popup dialog
    let popup = createPopupObject();
    popup.title = "Kick player?";
    popup.message = `Are you sure you want to kick ${playerName}?`;
    popup.question = true;
    popup.yesCB = () => {
      getGameRef()
        .child("playerList/" + playerName)
        .remove();
    };

    context.updateState({ popup: popup });
  };