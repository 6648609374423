export const findTopPlayerFromStats = (votingStats, context) => {
  // Create topPlayer object that will be returned
  let topPlayer = { name: "", votes: 0 };

  // Make sure votingStats is not empty
  if (typeof votingStats === "undefined") return topPlayer;

  // Sort through votingStats to fins topPlayer
  Object.entries(votingStats).forEach((stat) => {
    const playerName = stat[0];
    const numVotes = stat[1][context];
    if (numVotes > topPlayer.votes && numVotes > 1) {
      if (playerName !== "NONE") {
        topPlayer.name = playerName;
        topPlayer.votes = numVotes;
      }
    }
  });

  return topPlayer;
};
