import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import { GameContext } from "../contexts/gameContext";

export default class BigInfo extends Component {
  static contextType = GameContext;

  render() {
    const text1 = (
      <Grid item>
        <Typography variant="h4">{this.props.text1}</Typography>
      </Grid>
    );

    return (
      <React.Fragment>
        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
          mountOnEnter
          unmountOnExit
        >
          <Grid item>
            <Typography variant="h2">{this.props.title}</Typography>
          </Grid>
        </Zoom>

        {this.props.text1 ? text1 : null}

        {this.props.buttons.map((btn) => {
          return (
            <Zoom
              in={this.context.transition.in}
              timeout={this.context.transition.timeout}
              mountOnEnter
              unmountOnExit
              key={btn.text}
            >
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={btn.onClick}
                  disabled={btn.disabled}
                >
                  {btn.text}
                </Button>
              </Grid>
            </Zoom>
          );
        })}
      </React.Fragment>
    );
  }
}
