import {
  getLivingPlayers,
  didPlayersSeeInfo,
  changePlayersPropInDB,
} from "../../../utils/commonFunctions";
import SleepSheriff from "../../../audio/SleepSheriff.mp3";
import { changeGameStatus } from "../../../utils/changeGameStatus";
import { playAudio } from "../../../utils/playAudio";

export const adminSheriffDone = (context) => {
  //const audio_sleepSheriff = new Audio(SleepSheriff);

  const livingSheriffs = getLivingPlayers(context.game.playerList, "sheriff");

  // If all living sheriffs has seen info
  if (didPlayersSeeInfo(livingSheriffs)) {
    console.log("All living sheriffs has seen info");
    changePlayersPropInDB(context.game.playerList, "seenInfo", false);
    //audio_sleepSheriff.play();
    playAudio("sleepSheriff", context);

    // Set status to nightReport
    setTimeout(() => changeGameStatus("nightReport"), 5000);
  }
  // If all living sheriffs haven't seen info
  else {
    console.log("All living sheriff has NOT seen info");
  }
};
