import { getGameRef } from "./firebase";
import RandomString from "random-string";
import { ForbiddenNames } from "../utils/nameLists";
import { contextData } from "../contexts/gameContext";

// Changes a list of players certain prop with value in DB
export const changePlayersPropInDB = (playerList, prop, value) => {
  let playerlistUpdates = {};

  // Change prop in all players to value
  Object.keys(playerList).map((player) => {
    playerlistUpdates[`/${player}/${prop}`] = value;
  });

  // Update DB with new playerlist
  getGameRef().child("playerList").update(playerlistUpdates);
};

// Changes a player's certain prop with value in DB
export const changePlayerPropInDB = (name, prop, value) => {
  getGameRef().child(`playerList/${name}/${prop}`).set(value);
};

// Pass in array of player objects, returns true if all saw info
export const didPlayersSeeInfo = (playerList) => {
  const numPlayers = playerList.length;
  let numSeen = 0;

  playerList.map((player) => {
    if (player.seenInfo) numSeen++;
  });

  return numPlayers === numSeen;
};

// Clear local storage and reload page
export const clearAndReloadBrowser = () => {
  localStorage.clear();
  window.location.reload();
};

// Returns vote object checking if there are a majority vote on a player and who it is based number of voting players
export const doesPlayersAgreeOnVote = (
  numVoters,
  playerList,
  votesToKillNone
) => {
  const majority = Math.floor(numVoters / 2) + 1;

  let vote = { agree: false, player: "", message: "" };
  let maxVote = 0;
  let totalVotes = 0;

  // Get highest vote from players and add to totalVotes
  playerList.map((player) => {
    totalVotes += player.votes;
    if (player.votes > maxVote) {
      maxVote = player.votes;
      vote.player = player.name;
    }
  });

  // Add votesToKillNone to totalVotes
  totalVotes += votesToKillNone;

  // Get vote from votesToKillNone
  if (votesToKillNone > maxVote) {
    maxVote = votesToKillNone;
    vote.player = "NONE";
  }

  // Check if not everyone has voted
  if (numVoters > totalVotes)
    vote.message =
      "Waiting for " + (numVoters - totalVotes).toString() + " more votes.";
  else {
    vote.message =
      "You need " + majority + " votes on the same option to move on.";

    // Check if players agree on one option
    if (maxVote >= majority) {
      vote.agree = true;
      vote.message = "";
    }
  }

  return vote;
};

// Checks if game is on based on status
export const gameIsOn = (status) => {
  return status !== "cityWon" && status !== "mafiaWon";
};

// Generating a random 4 length uppercase string, making sure it's unique
export const generateGameCode = (gameList) => {
  let code = RandomString({ length: 4, numeric: false }).toUpperCase();
  while (gameList.find((game) => game === code)) {
    code = RandomString({ length: 4, numeric: false }).toUpperCase();
  }
  return code;
};

// Returns array of living players with specific role. Empty role means all roles
export const getLivingPlayers = (playerList, role) => {
  return Object.values(playerList).filter((player) => {
    if (player.alive && (role ? player.role === role : true)) return true;
  });
};


// Checks if we have been kicked/deleted from the game
export const kicked = (playerList, me) => {
  try {
    const players = Object.keys(playerList);
    return !players.includes(me);
  } catch (err) {
    return true;
  }
};

// When player chooses to leave game
export const leaveGame = () => {
  const { playerList, status } = contextData.game;
  const { name } = contextData.player;

  // If we were the last player, then remove game instead of player
  if (Object.keys(playerList).length === 1) getGameRef().remove();
  else {
    // If game is on, remove player
    if (gameIsOn(status)) removePlayer(name);

    const { admin } = playerList[name];

    // If admin, and game is still on, then pick new admin
    if (admin && gameIsOn(status))
      pickNewAdmin(getLivingPlayers(playerList), name);
  }

  clearAndReloadBrowser();
};

// Called by admin who died or left. Picks ned admin and send them a popup
export const pickNewAdmin = (livingPlayers, me) => {
  // Picks first living player
  let newAdmin = livingPlayers[0].name;

  // Loop to make sure we didn't pick ourselves
  // This can happen if the admin leaves the game
  for (let i = 0; newAdmin === me; i++) {
    newAdmin = livingPlayers[i].name;
  }

  // Set new admin
  changePlayerPropInDB(newAdmin, "admin", true);

  // Send new admin a popup message
  changePlayerPropInDB(newAdmin, "popup", "newAdmin");
};

// Remove player from game
export const removePlayer = async (player) => {
  await getGameRef()
    .child("playerList/" + player)
    .set(null);
};

export const savePlayersVotes = (playerList, context) => {
  console.log("savePlayersVotes called");
  Object.values(playerList).map((player) => {
    console.log(player.name, "votes for", player.votesFor);
    if (player.votesFor !== "") {
      let votedFor = { name: player.votesFor, context: context };
      getGameRef().child(`playerList/${player.name}/votedFor`).push(votedFor);
    }
  });
};

export const test = () => {
  console.log(contextData);
};

export const validatePlayerName = (name, playerList) => {
  // Make sure name is not empty
  if (name === "") {
    return { nameOk: false, error: "Write your name" };
  }

  if (/[~`!#$%&*+=\-\]\\';.,/{}|\\":<>]/g.test(name)) {
    return { nameOk: false, error: "Special characters are not ok" };
  }

  // Make sure name is not forbidden
  if (ForbiddenNames.includes(name)) {
    return { nameOk: false, error: "Name is forbidden" };
  }

  // Make sure name is not taken
  if (playerList && Object.keys(playerList).find((obj) => obj === name)) {
    return { nameOk: false, error: "Name is taken" };
  }

  // If there were no errors
  return { nameOk: true, error: "" };
};
