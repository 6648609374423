import { goToStep } from "./stepSwitch/goToStep";

export const checkGameCode = (state, updateState, context) => {
    const code = state.code;
    let gameList = state.gameList;
    let game = state.gameList[code];

    // Make sure code is not empty
    if (code === "") {
      updateState({ codeError: "Write game code" });
      return;
    }

    // Make sure code is correct
    if (!Object.keys(gameList).find((obj) => obj === code)) {
      updateState({ codeError: "Code is not correct" });
      return;
    }

    // Make sure game is open
    if (game.status !== "open") {
      updateState({ codeError: "Game is not open" });
      return;
    }

    // If we make it past all errors
    goToStep("Four", context, updateState);
  };