import {
  getLivingPlayers,
  didPlayersSeeInfo,
  changePlayersPropInDB,
} from "../../../utils/commonFunctions";
import SleepDoctor from "../../../audio/SleepDoctor.mp3";
import { changeGameStatus } from "../../../utils/changeGameStatus";
import { playAudio } from "../../../utils/playAudio";

// Admin checks if all doctors have seen info before moving game forward. Loop
export const adminDoctorDone = (context) => {
  const { playerList } = context.game;
  //const audio_sleepDoctor = new Audio(SleepDoctor);

  const livingDoctors = getLivingPlayers(playerList, "doctor");

  // If all living doctors has seen info
  if (didPlayersSeeInfo(livingDoctors)) {
    console.log("All living doctors has seen info");
    changePlayersPropInDB(playerList, "seenInfo", false);
    //audio_sleepDoctor.play();
    playAudio("sleepDoctor", context);

    // If game has a sheriff
    if (context.game.roles.sheriff) {
      console.log("The game has a sheriff");
      // If the sheriff is alive
      if (getLivingPlayers(playerList, "sheriff").length > 0) {
        console.log("The sheriff is alive");

        setTimeout(() => changeGameStatus("sheriff"), 5000);
      }
      // If the sheriff is dead
      else {
        // Fake sheriff
        setTimeout(() => changeGameStatus("fakeSheriff"), 5000);
      }
    }
    // If game doesn't have sheriff
    else {
      // Change status to nightReport
      setTimeout(() => changeGameStatus("nightReport"), 5000);
    }
  }
  // If all living doctors haven't seen info
  else {
    console.log("All living doctors has NOT seen info");
  }
};
