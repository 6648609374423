import React from "react";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import Grid from "@material-ui/core/Grid";

import { GameContext } from "../contexts/gameContext";

class Popup extends React.Component {
  static contextType = GameContext;

  state = {
    in: true,
  };

  styles = {
    popup: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      backgroundColor: "#191716",
      zIndex: 5000,
    },
    popupInner: {
      position: "absolute",
      left: "15%",
      top: "25%",
      right: "15%",
      margin: "auto",
      borderRadius: "20px",
      backgroundColor: "#f7ebec",
      color: "#191716",
      padding: "0 15px 30px 15px",
    },
  };

  closePopup = () => {
    const { timeout } = this.context.transition;

    let popup = Object.assign({}, this.context.popup);
    popup = Object.assign(popup, { active: false });

    this.setState({ in: false }, () => {
      setTimeout(() => {
        this.context.updateState({
          popup: popup,
        });
      }, timeout);
    });
  };

  answer = (event) => {
    // Checks if player pressed YES/OK
    const response = event.target.innerHTML !== "NO";

    // Execute callback
    const { yesCB, noCB } = this.context.popup;
    response ? yesCB() : noCB();

    // Close popup
    this.closePopup();
  };

  qButtons = () => {
    return (
      <Grid
        container
        item
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={this.answer}
          >
            YES
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={this.answer}
          >
            NO
          </Button>
        </Grid>
      </Grid>
    );
  };

  okButton = () => {
    return (
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={this.answer}
      >
        OK
      </Button>
    );
  };

  render() {
    const { popup, popupInner } = this.styles;
    const { title, message, question } = this.context.popup;

    return (
      <Zoom in={this.state.in} timeout={this.context.transition.timeout}>
        <div style={popup}>
          <div style={popupInner}>
            <h1>{title}</h1>
            <p>{message}</p>

            {question ? this.qButtons() : this.okButton()}
          </div>
        </div>
      </Zoom>
    );
  }
}

export default Popup;
