import {goToStep} from "./goToStep";

export const goBack = (state, context, updateState, routerHistory) => {
    switch (state.step) {
      case "One":
        context.changePage("FirstPage");
        break;

      case "Two":
        goToStep("One", context, updateState);
        break;

      case "Three":
        goToStep("One", context, updateState);
        break;

      case "Four":
        goToStep("Three", context, updateState);
        break;

      default:
        goToStep("One", context, updateState);
        break;
    }

    // Clear URL (if someone joined with link invite)
    if (typeof routerHistory !== "undefined") routerHistory.push("/");
  };