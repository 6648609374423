import { createInfoBoxObject } from "../../../utils/createObject";
import { seenInfo } from "../seenInfo";
import WakeCity from "../../../audio/WakeCity.mp3";
import Sad from "../../../audio/Sad.mp3";
import Celebration from "../../../audio/Celebration.mp3";
import { GoodNames, BadNames } from "../../../utils/nameLists";
import { changePlayerPropInDB } from "../../../utils/commonFunctions";
import { playAudio } from "../../../utils/playAudio";

export const displayNightReport = (context, setState) => {
  console.log("It's nightReport time!");

  //const audio_wakeCity = new Audio(WakeCity);
  //const audio_sad = new Audio(Sad);
  //const audio_celebration = new Audio(Celebration);

  const game = context.game;
  const killed = game.mafiaChose !== game.doctorChose;

  let textObject = createInfoBoxObject();
  textObject.text2 = game.mafiaChose;
  textObject.color = killed ? "secondary" : "primary";
  textObject.text3 = killed
    ? "was killed during the night"
    : "was saved during the night";
  textObject.showButton = true;
  textObject.onClick = () => seenInfo(false, context, setState);

  const playerName = context.player.name;
  const admin = context.game.playerList[playerName].admin;

  // If admin, wake city and check kill
  if (admin) {
    //audio_wakeCity.play();
    playAudio("wakeCity", context);
    // If someone was killed, kill them
    if (killed) {
      if (GoodNames.includes(game.mafiaChose)) playAudio("sad", context);
      if (BadNames.includes(game.mafiaChose)) playAudio("celebration", context);
      changePlayerPropInDB(game.mafiaChose, "alive", false);
    } else {
      if (GoodNames.includes(game.mafiaChose)) playAudio("celebration", context);
      if (BadNames.includes(game.mafiaChose)) playAudio("sad", context);
    }
  }

  setState({ textObject: textObject });
};
