import WakeDoctor from "../../../audio/WakeDoctor.mp3";
import { playAudio } from "../../../utils/playAudio";
import { cleanVotes } from "../cleanVotes";
import { displayNight } from "../displayFunctions/displayNight";

export const statusDoctor = (context, setState) => {
  console.log("It's doctor time!");
  //const audio_wakeDoctor = new Audio(WakeDoctor);

  const playerName = context.player.name;
  const player = context.game.playerList[playerName];

  if (player.admin) {
    //audio_wakeDoctor.play();
    playAudio("wakeDoctor", context);
    cleanVotes(context.game.playerList);
  }

  if (player.role === "doctor") context.changePage("PickPlayerPage");
  else displayNight(setState);
};
