export const ForbiddenNames = [
  "NONE",
  "MAFIA",
  "DOCTOR",
  "SHERIFF",
];

export const GoodNames = ["SKONTAN", "WEDIN", "JONATHAN"];

export const BadNames = ["MICKE", "MJ", "JÅFS", "MICKE JÅFS", "M", "MICKE J"];
