import React, { Component } from "react";
import { GameContext } from "../../contexts/gameContext";
import Zoom from "@material-ui/core/Zoom";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

class BackButton extends Component {
  static contextType = GameContext;

  render() {
    return (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
      >
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="default"
            onClick={this.props.goBack}
          >
            BACK
          </Button>
        </Grid>
      </Zoom>
    );
  }
}

export default BackButton;
