import { cleanChoseProps } from "../cleanChoseProps";
import { cleanVotes } from "../cleanVotes";
import SleepCity from "../../../audio/SleepCity.mp3";
import { getGameRef } from "../../../utils/firebase";
import { playAudio } from "../../../utils/playAudio";

export const adminNight = (context) => {
  //const audio_sleepCity = new Audio(SleepCity);

  // Clean up prev night/day
  cleanChoseProps(context.game.doctorChose);
  cleanVotes(context.game.playerList);

  //audio_sleepCity.play();
  playAudio("sleepCity", context);
  setTimeout(() => getGameRef().child("status").set("mafia"), 7000);
};
