import WakeSheriff from "../../../audio/WakeSheriff.mp3";
import { playAudio } from "../../../utils/playAudio";
import { cleanVotes } from "../cleanVotes";
import { displayNight } from "../displayFunctions/displayNight";

export const statusSheriff = (context, setState) => {
  console.log("It's sheriff time!");

  //const audio_wakeSheriff = new Audio(WakeSheriff);

  const playerName = context.player.name;
  const player = context.game.playerList[playerName];

  if (player.admin) {
    //audio_wakeSheriff.play();
    playAudio("wakeSheriff", context);
    cleanVotes(context.game.playerList);
  }

  if (player.role === "sheriff") context.changePage("PickPlayerPage");
  else displayNight(setState);
};
