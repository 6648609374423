import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as firebase from "firebase";
import { getUserRef, increaseUserStat } from "../../../utils/firebase";
import Typography from "@material-ui/core/Typography";
import { createUserInDb } from "./createUserInDb";
import { getFavoriteRole } from "./getFavoriteRole";
import { AppContext } from "../../../contexts/appContext";

class MyProfile extends Component {
  static contextType = AppContext;

  state = {
    userData: {},
  };

  componentDidMount() {
    getUserRef(this.context.user.uid).on("value", this.firebaseListener);
  }

  firebaseListener = (snap) => {
    // Make sure snapshot (user) even exists
    if (snap.exists()) {
      this.setState({
        userData: snap.val(),
      });

      console.log("Received update from firebase!");
    } else {
      createUserInDb(this.context.user.uid);
    }
  };

  onSignOut = () => {
    firebase.auth().signOut();
  };

  test = () => {
    increaseUserStat(this.context.user.uid, "numCreatedGames");
  };

  render() {
    return (
      <React.Fragment>
        <Grid item>
          <h1>My profile</h1>
        </Grid>
        <Grid item>
          <h2>
            Hello{" "}
            {this.context.user.displayName
              ? this.context.user.displayName
              : this.context.user.email}
          </h2>
        </Grid>

        <Grid item>
          <img
            style={{ width: "200px", borderRadius: "100px" }}
            src={this.context.user.photoURL}
          />
        </Grid>

        {/*
          <Grid item>
          <button onClick={this.test}>Test</button>
        </Grid>
        */}

        <Grid item container direction="column">
          <Grid item>
            <Typography variant="body1">
              Points: {this.state.userData.points || "0" }
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              Favorite role: {getFavoriteRole(this.state.userData.roles).role}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              Number of games played: {this.state.userData.numGamesPlayed}
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={this.onSignOut}
          >
            SIGN OUT
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

export default MyProfile;
