import { createMuiTheme } from "@material-ui/core/styles";

export const mainProps = {
  root: {},
    MuiChip: {
      root: {
        height: "48px",
        fontSize: "1rem",
        borderRadius: "24px",
      },
      avatar: {
        height: "36px",
        width: "36px",
      },
    },
    MuiAvatar: {
      root: {
        height: "36px",
        width: "36px",
      },
    },
    MuiGrid: {
      "spacing-xs-4": {
        width: "100%",
        margin: "auto",
      },
    },
    MuiButton: {
      contained: {
        borderRadius: "25px",
      },
    },
    MuiTypography: {
      root: {
        color: "#DFD9E2",
      },
    },
    MuiNativeSelect: {
      root: {
        textAlign: "center",
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: "20px",
        margin: "0px",
        backgroundColor: "#FFFD82",
        color: "#191716",
        padding: "4px 9px",
      },
      touch: {
        maxWidth: "25px",
        padding: "3px 8px",
        fontSize: "15px",
        fontWeight: "500",
      },
      tooltipPlacementTop: {
        margin: "7px",
        animation:
          "1000ms ease-in-out 0ms infinite normal none running testAnimate",
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: "#191716",
        width: "96%",
        position: "fixed",
        bottom: 10,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        "&$selected": { color: "#4B749E" },
      },
    },

};

export const mainTheme = createMuiTheme({
  overrides: {
    ...mainProps
  },
  palette: {
    primary: {
      main: "#26547C",
    },
    secondary: {
      main: "#D04E46",
    },
    default: {
      main: "#FFFD82",
    },
    success: {
      main:"#60A561",
    },
    type: "dark",
  },
});