import * as firebase from "firebase";

export const firebaseConfig = {
  apiKey: "AIzaSyATzeCyPdVnuIi_0NT6VByDW6a_ZdmTdis",
  authDomain: "test-2afe9.firebaseapp.com",
  databaseURL: "https://test-2afe9.firebaseio.com",
  projectId: "test-2afe9",
  storageBucket: "test-2afe9.appspot.com",
  messagingSenderId: "262517024630",
  appId: "1:262517024630:web:318ff40c84776e4b5abc57",
  measurementId: "G-XVQ6JQC1D6",
};

firebase.initializeApp(firebaseConfig);

export const rootRef = () => {
  return firebase.database().ref();
};

export const gameListRef = rootRef().child("gameList");

export const getGameRef = () => {
  let code = localStorage.getItem("code");
  return code ? gameListRef.child(code) : null;
};

export const getUsersRef = () => {
  return rootRef().child("users");
};

export const getUserRef = (uid) => {
  return getUsersRef().child(uid);
};

export const increaseUserStat = (uid, stat) => {
  const statRef = getUserRef(uid).child(stat);

  statRef.once("value").then((snap) => {
    statRef.set(snap.val() + 1);
  });
};