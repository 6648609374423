import WakeSheriff from "../../../audio/WakeSheriff.mp3";
import { changeGameStatus } from "../../../utils/changeGameStatus";
import { playAudio } from "../../../utils/playAudio";

// Fake sheriff wake up
export const statusFakeSheriff = (context) => {
  console.log("fakeSheriff() called");

  //const audio_wakeSheriff = new Audio(WakeSheriff);

  const playerName = context.player.name;
  const admin = context.game.playerList[playerName].admin;

  if (admin) {
    //audio_wakeSheriff.play();
    playAudio("wakeSheriff", context);

    // Random time between 1 and 15 seconds before doctor is "done"
    const time = Math.random() * 15 * 1000;
    setTimeout(() => {
      changeGameStatus("sheriffDone");
    }, time);
  }
};
