import {
  getLivingPlayers,
  didPlayersSeeInfo,
  changePlayersPropInDB,
} from "../../../utils/commonFunctions";
import { changeGameStatus } from "../../../utils/changeGameStatus";

export const adminDay = (context) => {
  const { playerList } = context.game;

  const livingPlayers = getLivingPlayers(playerList);

  // If all living players has seen info
  if (didPlayersSeeInfo(livingPlayers)) {
    // Clean seen info
    changePlayersPropInDB(playerList, "seenInfo", false);

    // Change game status to dayVote
    changeGameStatus("dayVote");
  }
};
