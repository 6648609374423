import { displayDoctorChose } from "../displayFunctions/displayDoctorChose";
import { adminDoctorDone } from "../adminFunctions/adminDoctorDone";
export const statusDoctorDone = (context, setState) => {
  console.log("Doctor is done!");

  const playerName = context.player.name;
  const player = context.game.playerList[playerName];
  const role = player.role;
  const seenInfo = player.seenInfo;
  const admin = player.admin;

  if (role === "doctor" && !seenInfo) displayDoctorChose(context, setState);

  if (admin) adminDoctorDone(context);
};
