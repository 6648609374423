import React, { Component } from "react";
import GameCode from "../../components/GameCode";
import InviteLink from "../../components/InviteLink";
import PlayerList from "../../components/PlayerList";
import AdminPanel from "../../components/AdminPanel";
import Grid from "@material-ui/core/Grid";
import { GameContext } from "../../contexts/gameContext";
import { kicked } from "../../utils/commonFunctions";
import { popupRouter } from "../../utils/popupRouter";
import {kickPlayer} from "./kickPlayer";

import { ThemeProvider } from "@material-ui/styles";
import gameLobbyTheme from "../../themes/gameLobbyTheme";

export default class GameLobby extends Component {
  static contextType = GameContext;

  state = {
    oldStatus: this.context.game.status,
  };

  colorFunction = (player) => {
    let myPlayer = this.context.game.playerList[this.context.player.name];

    // Make sure player isn't undefined
    myPlayer = typeof myPlayer === "undefined" ? { name: "" } : myPlayer;

    return player.name === myPlayer.name ? "secondary" : "primary";
  };

  componentDidUpdate() {
    const { game, player, updateState, connected } = this.context;
    const { status } = game;

    // Make sure status has changed
    if (status !== this.state.oldStatus) {
      this.setState({ oldStatus: status });

      // Check if game has started
      if (this.didGameStart(status)) {
        console.log("Game started!");
        this.context.changePage("InfoPage");
      }
    }

    // Check if we were kicked out
    if (connected && kicked(game.playerList, player.name)) {
      updateState({ connected: false, popup: popupRouter("kickedOut", false) });
    }
  }

  didGameStart = (status) => {
    return status !== "open";
  };

  render() {
    const code = this.context.game.code;
    const players = Object.values(this.context.game.playerList);
    let myPlayer = this.context.game.playerList[this.context.player.name];

    // Make sure player isn't undefined
    myPlayer = typeof myPlayer === "undefined" ? { admin: false } : myPlayer;

    return (
      <ThemeProvider theme={gameLobbyTheme}>
        <Grid container item direction="column" alignItems="center" spacing={4}>
          <GameCode code={code} />
            <InviteLink code={code} />
            {myPlayer.admin ? <AdminPanel /> : null}
            <PlayerList players={players} myPlayer={myPlayer} colorFunction={this.colorFunction} onClick={myPlayer.admin? kickPlayer : null} />
        </Grid>
      </ThemeProvider>
    );
  }
}
