import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FacebookLoginButton from "../../../images/login-with-facebook-button.png";

import * as firebase from "firebase";

class SignInWithFacebookButton extends Component {
  state = {
    mouseDown: false,
    provider: new firebase.auth.FacebookAuthProvider(),
  };

  handleMouseDown = () => {
    console.log("Mouse down!");
    this.setState({ mouseDown: true });
  };

  handleMouseUp = () => {
    console.log("Mouse up!");
    this.setState({ mouseDown: false });
  };

  render() {
    return (
      <Grid item>
        <Button
          style={{
            width: "191px",
            height: "46px",
            backgroundImage: `url(${FacebookLoginButton})`,
            backgroundSize: "cover",
          }}
          size="medium"
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onTouchStart={this.handleMouseDown}
          onTouchEnd={this.handleMouseUp}
          onClick={() => {
            firebase.auth().signInWithRedirect(this.state.provider);
          }}
        >
          {" "}
        </Button>
      </Grid>
    );
  }
}

export default SignInWithFacebookButton;
