import { didIDie } from "../didIDie";
import { gameOver } from "../gameOver";
import { displayNight } from "../displayFunctions/displayNight";
import { adminNight } from "../adminFunctions/adminNight";

export const statusNight = (context, setState) => {
  console.log("It's night time!");

  // Make sure we didn't die
  if (didIDie(context)) gameOver(context);

  displayNight(setState);

  const playerName = context.player.name;
  const player = context.game.playerList[playerName];

  if (player.admin) adminNight(context);
};
