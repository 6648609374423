export const stepExplainationObj = {
  createOrJoin: {
    title: "Create or Join Game",
    text:
      "Do you want to create your own game, or do you want to join your friend's game? \n \n \
      If you are creating your own game, all you need to do is enter your name. Creating a game makes you the admin of the game. This means that you get to set up the game's settings in the  Game Lobby. Your device will also be used as a speaker throughout the game, so keep you volume loud! If your player dies during the game, another player will become the admin instead. \n \n \
      If you are joining a friend's game, you need to do ask for their game code.",
  },
  gameLobby: {
    title: "Game Lobby",
    text:
      "The Game Lobby will look different depending on if you are the admin or not. \n \n \
  If you are the admin, then you will be able to decide the different number of roles for the game. You are also able to kick any player from the game by pressing their name. When everyone has joined and the number of roles have been picked, you are ready to start the game! \n \n \
  If you are not the admin then all you get to do here is see who's in the game while you wait for the game to start.",
  },
  gameStart: {
    title: "Game Start",
    text:
      "The first thing in the game is that you will receive your role. Make sure to keep your display hidden from other players. ",
  },
  nightTime: {
    title: "Night Time",
    text:
      'Every night in the game will be preceeded by the admin device saying: "City goes to sleep". During the night, every player needs to keep their eyes closed. You are only allowed to open your eyes when the admin device either tells your role to wake up, or if it tells the city to wake up.',
  },
  mafia: { title: "Mafia", text: "test" },
  doctor: { title: "Doctor", text: "test" },
  sheriff: { title: "Sheriff", text: "test" },
  nightReport: { title: "Night Report", text: "test" },
  discussionTime: { title: "Discussion Time", text: "test" },
  dayVote: { title: "Day Vote", text: "test" },
  repeat: { title: "Repeat", text: "test" },
  gameOver: { title: "Game Over", text: "test" },
  empty: { title: "", text: "" },
};
