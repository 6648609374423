import React, { Component } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PeopleIcon from "@material-ui/icons/People";
import WorkIcon from "@material-ui/icons/Work";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import ErrorIcon from "@material-ui/icons/Error";
import StarIcon from "@material-ui/icons/Star";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AssignmentIcon from "@material-ui/icons/Assignment";
import GavelIcon from "@material-ui/icons/Gavel";
import CancelIcon from "@material-ui/icons/Cancel";
import BlockIcon from "@material-ui/icons/Block";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ReplayIcon from "@material-ui/icons/Replay";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { stepExplainationObj } from "./stepExplainationObj";

class HowToPlayPage extends Component {
  state = { dialog: { open: false, name: "empty" } };

  handleClick = (event) => {
    const name = event.target.getAttribute("name");
    this.setState({ dialog: { open: true, name: name } });
  };

  handleClose = () => {
    this.setState({ dialog: { open: false, name: "empty" } });
  };

  render() {
    return (
      <React.Fragment>
        <Grid item>
          <Typography variant="h3">How to play</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Press any step below to see more info.
          </Typography>
        </Grid>

        <Dialog onClose={this.handleClose} open={this.state.dialog.open}>
          <DialogTitle>
            {stepExplainationObj[this.state.dialog.name].title}
            <CloseIcon />
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ whiteSpace: "pre-line" }}>
              {stepExplainationObj[this.state.dialog.name].text}
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Timeline align="alternate" style={{ padding: "0", width: "100%" }}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <AddCircleIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="createOrJoin"
                onClick={this.handleClick}
              >
                Create or join game
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <PeopleIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="gameLobby"
                onClick={this.handleClick}
              >
                Game Lobby
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <PlayCircleFilledIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="gameStart"
                onClick={this.handleClick}
              >
                Game start
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <NightsStayIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="nightTime"
                onClick={this.handleClick}
              >
                Night time
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <ErrorIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="mafia"
                onClick={this.handleClick}
              >
                Mafia
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <LocalHospitalIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="doctor"
                onClick={this.handleClick}
              >
                Doctor
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <StarIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="sheriff"
                onClick={this.handleClick}
              >
                Sheriff
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <AssignmentIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="nightReport"
                onClick={this.handleClick}
              >
                Night report
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <QuestionAnswerIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="discussionTime"
                onClick={this.handleClick}
              >
                Discussion time
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <GavelIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="dayVote"
                onClick={this.handleClick}
              >
                Day vote
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <ReplayIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="repeat"
                onClick={this.handleClick}
              >
                Repeat
              </Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot style={{ padding: "10px" }} color="primary">
                <AccessibilityNewIcon style={{ fontSize: "55px" }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ textAlign: "center" }}>
              <Typography
                variant="h6"
                style={{ paddingBottom: "130px" }}
                name="gameOver"
                onClick={this.handleClick}
              >
                Game Over
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </React.Fragment>
    );
  }
}

export default HowToPlayPage;
