import React, { Component } from "react";
import InfoBox from "../../components/InfoBox";
import { GameContext } from "../../contexts/gameContext";
import { GoodNames, BadNames } from "../../utils/nameLists";
import {
  changePlayersPropInDB,
  changePlayerPropInDB,
  didPlayersSeeInfo,
  getLivingPlayers,
  pickNewAdmin,
} from "../../utils/commonFunctions";
import { createInfoBoxObject } from "../../utils/createObject";
import { getGameRef } from "../../utils/firebase";
import { cleanChoseProps } from "./cleanChoseProps";
import { cleanVotes } from "./cleanVotes";
import { statusSwitch } from "./statusSwitch";
import { displayWait } from "./displayFunctions/displayWait";
import { displayNight } from "./displayFunctions/displayNight";
import { didIDie } from "./didIDie";

import WakeCity from "../../audio/WakeCity.mp3";
import SleepCity from "../../audio/SleepCity.mp3";
import WakeMafia from "../../audio/WakeMafia.mp3";
import SleepMafia from "../../audio/SleepMafia.mp3";
import WakeDoctor from "../../audio/WakeDoctor.mp3";
import SleepDoctor from "../../audio/SleepDoctor.mp3";
import WakeSheriff from "../../audio/WakeSheriff.mp3";
import SleepSheriff from "../../audio/SleepSheriff.mp3";
import Sad from "../../audio/Sad.mp3";
import Celebration from "../../audio/Celebration.mp3";

import { gameOver } from "./gameOver";
import { adminFunctionsSwitch } from "./adminFunctions/adminFunctionsSwitch";

export default class InfoPage extends Component {
  static contextType = GameContext;

  state = {
    textObject: {
      text1: "Loading game",
      color: "",
      text2: "",
      text3: "",
      showButton: false,
      showLoader: true,
      onClick: () => {
        console.log("Default function");
      },
    },
    oldStatus: this.context.game.status,
  };

  changeState = (newState) => {
    this.setState(newState);
  };

  componentDidMount() {
    statusSwitch(this.context, this.changeState);
  }

  componentDidUpdate() {
    const status = this.context.game.status;
    const playerName = this.context.player.name;
    const player = this.context.game.playerList[playerName];

    // If status has changed
    if (status !== this.state.oldStatus) {
      this.setState({ oldStatus: status });

      statusSwitch(this.context, this.changeState);
    } else {
      // If status is still the same but player is admin
      if (player.admin) {
        adminFunctionsSwitch(this.context);
      }
    }
  }

  render() {
    return <InfoBox textObject={this.state.textObject} />;
  }
}
