import { adminMafiaDone } from "./adminMafiaDone";
import { adminSheriffDone } from "./adminSheriffDone";
import { adminNight } from "./adminNight";
import { adminAssigned } from "./adminAssigned";
import { adminDoctorDone } from "./adminDoctorDone";
import { adminNightReport } from "./adminNightReport";
import { adminDay } from "./adminDay";
import { adminDayReport } from "./adminDayReport";
import { adminVoteDoneCheck } from "../../PickPlayerPage/adminVoteDoneCheck";

export const adminFunctionsSwitch = (context) => {
  const { status, playerList, votesToKillNone } = context.game;

  switch (status) {
    case "mafia":
      adminVoteDoneCheck("mafia", playerList, votesToKillNone);
      break;

    case "doctor":
      adminVoteDoneCheck("doctor", playerList, votesToKillNone);
      break;

    case "doctorDone":
      adminDoctorDone(context);
      break;

    case "sheriff":
      adminVoteDoneCheck("sheriff", playerList, votesToKillNone);
      break;

    case "mafiaDone":
      adminMafiaDone(context);
      break;

    case "nightReport":
      adminNightReport(context);
      break;

    case "day":
      adminDay(context);
      break;

    case "dayReport":
      adminDayReport(context);
      break;

    case "assigned":
      adminAssigned(context);
      break;

    case "sheriffDone":
      adminSheriffDone(context);
      break;
  }
};
