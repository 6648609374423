import { didSomeoneWin } from "../didSomeoneWin";
import { changeGameStatus } from "../../../utils/changeGameStatus";
import { didIDie } from "../didIDie";
import { getLivingPlayers, pickNewAdmin } from "../../../utils/commonFunctions";
import { gameOver } from "../gameOver";
import { displayWait } from "../displayFunctions/displayWait";

export const statusNightReportDone = (context, setState) => {
  const playerName = context.player.name;
  const admin = context.game.playerList[playerName].admin;

  // If admin, check if someone won
  if (admin) {
    const win = didSomeoneWin(context);

    if (win) {
      changeGameStatus(win + "Won");
      return;
    }
  }

  // Check if we died
  if (didIDie(context)) {
    // If admin then pick new admin
    if (admin)
      pickNewAdmin(
        getLivingPlayers(context.game.playerList),
        context.player.name
      );
    console.log("You died!");

    gameOver(context);
  } else {
    if (admin) changeGameStatus("day");

    displayWait(setState);
  }
};
