/*
Example roles object:
{
  mafia: 1,
  doctor: 2, 
  sheriff: 0,
}
*/


export const getFavoriteRole = (roles) => {
  let favorite = { role: "", number: 0 };

  // Make sure roles are not undefined
  if (typeof roles === "undefined") return favorite;

  Object.entries(roles).forEach((role) => {
    if (role[1] > favorite.number)
      favorite = { role: role[0], number: role[1] };
  });

  return favorite;
};
