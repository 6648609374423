import {
  getLivingPlayers,
  doesPlayersAgreeOnVote,
  savePlayersVotes,
} from "../../utils/commonFunctions";
import { getGameRef } from "../../utils/firebase";
import { changeGameStatus } from "../../utils/changeGameStatus";

export const adminVoteDoneCheck = (role, playerList, votesToKillNone) => {
  const voters = role === "city" ? "" : role;

  const numVoters = getLivingPlayers(playerList, voters).length;

  const vote = doesPlayersAgreeOnVote(
    numVoters,
    Object.values(playerList),
    votesToKillNone
  );

  // If players agree on vote
  if (vote.agree) {
    // Save players votes in votedFor
    savePlayersVotes(playerList, role);

    // Change Xchose in db with player name
    const child = role + "Chose";
    getGameRef().child(child).set(vote.player);

    // Change game status to XDone or dayReport
    const status = role === "city" ? "dayReport" : role + "Done";
    changeGameStatus(status);
  }

  // If there wasn't agreement, then update voteMessage in db
  else getGameRef().child("voteMessage").set(vote.message);
};
