import React, { Component } from "react";
import PickPlayer from "../../components/PickPlayer";
import { GameContext } from "../../contexts/gameContext";
import { createPickPlayerObject } from "../../utils/createObject";
import { getGameRef } from "../../utils/firebase";
import { getLivingPlayers } from "../../utils/commonFunctions";
import { adminVoteDoneCheck } from "../PickPlayerPage/adminVoteDoneCheck";

export default class PickPlayerPage extends Component {
  static contextType = GameContext;

  state = {
    pickObject: {
      title: "",
      infoText: "",
      buttonColor: "",
      clickedColor: "",
      players: [],
      onClick: () => {},
    },
    oldStatus: this.context.game.status,
    oldPlayerList: this.context.game.playerList,
    oldVoteMessage: this.context.game.voteMessage,
  };

  componentDidMount() {
    this.statusRouter();
  }

  componentDidUpdate(prevProps, prevState) {
    const playerName = this.context.player.name;
    const { status, playerList, voteMessage } = this.context.game;
    const player = playerList[playerName];

    if (
      status !== this.state.oldStatus ||
      JSON.stringify(playerList) !== JSON.stringify(this.state.oldPlayerList) ||
      voteMessage !== this.state.oldVoteMessage
    ) {
      this.statusRouter();
      this.setState({
        oldStatus: status,
        oldPlayerList: playerList,
        oldVoteMessage: voteMessage,
      });

      if (player.admin) {
        this.adminRouter();
      }
    } else {
      // If status is still the same but player is admin
      if (player.admin) {
        this.adminRouter();
      }
    }
  }

  adminRouter = () => {
    const status = this.context.game.status;

    switch (status) {
      case "mafia":
        this.adminMafia();
        break;

      case "doctor":
        this.adminDoctor();
        break;

      case "sheriff":
        this.adminSheriff();
        break;

      case "dayVote":
        this.adminDayVote();
        break;
    }
  };

  statusRouter = () => {
    console.log("Status router called!");
    switch (this.context.game.status) {
      case "mafia":
        this.mafia();
        break;

      case "doctor":
        this.doctor();
        break;

      case "sheriff":
        this.sheriff();
        break;

      case "dayVote":
        this.dayVote();
        break;

      default:
        this.backToInfoPage();
    }
  };

  adminMafia = () => {
    adminVoteDoneCheck(
      "mafia",
      this.context.game.playerList,
      this.context.game.votesToKillNone
    );
  };

  adminDoctor = () => {
    adminVoteDoneCheck(
      "doctor",
      this.context.game.playerList,
      this.context.game.votesToKillNone
    );
  };

  adminSheriff = () => {
    adminVoteDoneCheck(
      "sheriff",
      this.context.game.playerList,
      this.context.game.votesToKillNone
    );
  };

  adminDayVote = () => {
    adminVoteDoneCheck(
      "city",
      this.context.game.playerList,
      this.context.game.votesToKillNone
    );
  };

  backToInfoPage = () => {
    this.context.changePage("InfoPage");
  };

  mafia = () => {
    console.log("mafia() called");

    const numLivingPlayers = getLivingPlayers(
      this.context.game.playerList,
      "mafia"
    ).length;
    const majority = Math.floor(numLivingPlayers / 2) + 1;

    let pickObject = createPickPlayerObject();
    pickObject.title = "Who do you want to kill?";
    pickObject.infoText = this.context.game.voteMessage;
    pickObject.buttonColor = "secondary";
    pickObject.clickedColor = "primary";
    pickObject.players = getLivingPlayers(this.context.game.playerList);
    pickObject.onClick = this.vote;

    this.setState({ pickObject: pickObject });
  };

  doctor = () => {
    console.log("doctor() called");

    const numLivingPlayers = getLivingPlayers(
      this.context.game.playerList,
      "doctor"
    ).length;
    const majority = Math.floor(numLivingPlayers / 2) + 1;

    let pickObject = createPickPlayerObject();
    pickObject.title = "Who do you want to save?";
    pickObject.infoText = this.context.game.voteMessage;
    pickObject.buttonColor = "primary";
    pickObject.clickedColor = "secondary";
    pickObject.players = getLivingPlayers(this.context.game.playerList);
    pickObject.onClick = this.vote;

    this.setState({ pickObject: pickObject });
  };

  sheriff = () => {
    console.log("sheriff() called");

    const numLivingPlayers = getLivingPlayers(
      this.context.game.playerList,
      "sheriff"
    ).length;
    const majority = Math.floor(numLivingPlayers / 2) + 1;

    let pickObject = createPickPlayerObject();
    pickObject.title = "Who do you want to investigate?";
    pickObject.infoText = this.context.game.voteMessage;
    pickObject.buttonColor = "primary";
    pickObject.clickedColor = "secondary";
    pickObject.players = getLivingPlayers(this.context.game.playerList);
    pickObject.onClick = this.vote;

    this.setState({ pickObject: pickObject });
  };

  dayVote = () => {
    console.log("day() called");

    const numLivingPlayers = getLivingPlayers(this.context.game.playerList)
      .length;
    const majority = Math.floor(numLivingPlayers / 2) + 1;

    const votesToKillNone = this.context.game.votesToKillNone;

    const noneButton = { name: "NONE", avatar:"", votes: votesToKillNone };

    let pickObject = createPickPlayerObject();
    pickObject.title = "Who do you think is a mafia?";
    pickObject.infoText = this.context.game.voteMessage;
    pickObject.buttonColor = "default";
    pickObject.clickedColor = "primary";
    pickObject.players = getLivingPlayers(this.context.game.playerList);
    pickObject.onClick = this.vote;
    pickObject.players.push(noneButton);

    this.setState({ pickObject: pickObject });
  };

  changeVotes = (player, diff) => {
    let votes = 0;

    if (player === "NONE") {
      votes = this.context.game.votesToKillNone;
      getGameRef()
        .child("votesToKillNone")
        .set(votes + diff);
    } else {
      votes = this.context.game.playerList[player].votes;
      const path = "playerList/" + player + "/votes";
      getGameRef()
        .child(path)
        .set(votes + diff);
    }
  };

  changeVotesFor = (votesFor) => {
    const player = this.context.player.name;
    const path = "playerList/" + player + "/votesFor";

    getGameRef().child(path).set(votesFor);
  };

  vote = (votedPlayer) => {
    const playerName = this.context.player.name;
    const player = this.context.game.playerList[playerName];
    const oldVote = player.votesFor;

    // Did we vote for someone before this?
    if (oldVote) {
      // Remove vote from that player
      this.changeVotes(oldVote, -1);
    }

    // Did we vote for the same person as before?
    if (votedPlayer === oldVote) {
      // Clean out votesFor prop on our player
      this.changeVotesFor("");
    }
    // If we voted for a new person
    else {
      // changeVotesFor has to be first in order for saveVotes to work
      this.changeVotesFor(votedPlayer);
      this.changeVotes(votedPlayer, 1);
    }
  };

  choosePlayer = (chosenPlayer) => {
    const status = this.context.game.status;
    const whoChose = status === "day" ? "city" : status;
    const path = whoChose + "Chose";

    getGameRef().child(path).set(chosenPlayer);
  };

  render() {
    return this.state.pickObject.title ? (
      <PickPlayer pickObject={this.state.pickObject} />
    ) : null;
  }
}
