import { displayAssignedRole } from "./displayFunctions/displayAssignedRole";
import { displayNightReport } from "./displayFunctions/displayNightReport";
import { statusNightReportDone } from "./nonDisplayStatusFunctions/statusNightReportDone";
import { statusNight } from "./nonDisplayStatusFunctions/statusNight";
import { statusMafia } from "./nonDisplayStatusFunctions/statusMafia";
import { statusMafiaDone } from "./nonDisplayStatusFunctions/statusMafiaDone";
import { statusDoctor } from "./nonDisplayStatusFunctions/statusDoctor";
import { statusFakeDoctor } from "./nonDisplayStatusFunctions/statusFakeDoctor";
import { statusDoctorDone } from "./nonDisplayStatusFunctions/statusDoctorDone";
import { statusSheriff } from "./nonDisplayStatusFunctions/statusSheriff";
import { statusSheriffDone } from "./nonDisplayStatusFunctions/statusSheriffDone";
import { statusDay } from "./nonDisplayStatusFunctions/statusDay";
import { statusDayReport } from "./nonDisplayStatusFunctions/statusDayReport";
import { statusDayReportDone } from "./nonDisplayStatusFunctions/statusDayReportDone";
import { statusFakeSheriff } from "./nonDisplayStatusFunctions/statusFakeSheriff";
import { gameOver } from "./gameOver";

export const statusSwitch = (context, setState) => {
  console.log("Status switch called!");
  const status = context.game.status;

  switch (status) {
    case "assigned":
      displayAssignedRole(context, setState);
      break;

    case "night":
      statusNight(context, setState);
      break;

    case "mafia":
      statusMafia(context, setState);
      break;

    case "mafiaDone":
      statusMafiaDone(context, setState);
      break;

    case "doctor":
      statusDoctor(context, setState);
      break;

    case "doctorDone":
      statusDoctorDone(context, setState);
      break;

    case "fakeDoctor":
      statusFakeDoctor(context);
      break;

    case "sheriff":
      statusSheriff(context, setState);
      break;

    case "sheriffDone":
      statusSheriffDone(context, setState);
      break;

    case "fakeSheriff":
      statusFakeSheriff(context, setState);
      break;

    case "nightReport":
      displayNightReport(context, setState);
      break;

    case "nightReportDone":
      statusNightReportDone(context, setState);
      break;

    case "day":
      statusDay(context, setState);
      break;

    case "dayVote":
      context.changePage("PickPlayerPage");
      break;

    case "dayReport":
      statusDayReport(context, setState);
      break;

    case "dayReportDone":
      statusDayReportDone(context, setState);
      break;

    case "cityWon":
      gameOver(context);
      break;

    case "mafiaWon":
      gameOver(context);
      break;
  }
};
