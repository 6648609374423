import React, { Component } from "react";
import * as firebase from "firebase";
import * as firebaseui from "firebaseui";
import SignInOrUp from "./SignInOrUp";
import MyProfile from "./MyProfile";
import { AppContext } from "../../contexts/appContext";

class UserProfilePage extends Component {
  static contextType = AppContext;

  state = {};

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        {this.context.user ? <MyProfile /> : <SignInOrUp />}
      </React.Fragment>
    );
  }
}

export default UserProfilePage;
