export const onChange = (event, updateState) => {
    let error = event.target.name + "Error";
    let text = event.target.value
      .toUpperCase()
      .slice(0, 15)
      .replace(/  +/g, " ");

    // Make sure text doesn't start with space
    text = text === " " ? "" : text;

    updateState({
      [event.target.name]: text,
      [error]: "",
    });
  };