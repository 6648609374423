import WakeMafia from "../../../audio/WakeMafia.mp3";
import { playAudio } from "../../../utils/playAudio";
import { cleanVotes } from "../cleanVotes";
import { displayNight } from "../displayFunctions/displayNight";

export const statusMafia = (context, setState) => {
  console.log("It's mafia time!");

  //const audio_wakeMafia = new Audio(WakeMafia);

  const playerName = context.player.name;
  const player = context.game.playerList[playerName];

  if (player.admin) {
    //audio_wakeMafia.play();
    playAudio("wakeMafia", context);
    cleanVotes(context.game.playerList);
  }

  if (player.role === "mafia") context.changePage("PickPlayerPage");
  else displayNight(setState);
};
