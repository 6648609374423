export const getVotingStatsFromPlayer = (playerList, playerName) => {
  //Save player
  const player = playerList[playerName];

  // First make sure the player voted
  if (typeof player.votedFor === "undefined") return false;

  // Create votingStats object with all the players that player voted for and NONE
  let votingStats = { NONE: { hate: 0 } };
  Object.values(player.votedFor).forEach((vote) => {
    votingStats[vote.name] = { hate: 0, love: 0, suspect: 0 };
  });

  // Sort each of the players votes into votingStats
  Object.values(player.votedFor).forEach((vote) => {
    switch (vote.context) {
      case "mafia":
      case "city":
        votingStats[vote.name].hate++;
        break;

      case "doctor":
        votingStats[vote.name].love++;
        break;

      case "sheriff":
        votingStats[vote.name].suspect++;
        break;
    }
  });

  return votingStats;
};
