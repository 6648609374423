import { getLivingPlayers } from "../../utils/commonFunctions";

// Returns string with winner or false
export const didSomeoneWin = (context) => {
  const { playerList } = context.game;

  const numLivingPlayers = getLivingPlayers(playerList).length;

  const numLivingMafias = getLivingPlayers(playerList, "mafia").length;

  // Did city win?
  if (numLivingMafias === 0) return "city";

  // Did mafia win?
  if (numLivingMafias >= numLivingPlayers / 2) return "mafia";

  // Else, no one won
  return false;
};
