export const createUserObject = () => {
  return {
    numGameDaysPlayed: 0, // done
    numGamesPlayed: 0,
    numVotesLeadToKill: 0,
    numGamesLost: 0,
    numVotesLeadToSave: 0,
    numGamesWon: 0,
    roles: { mafia: 0, sheriff: 0, doctor: 0, farmer: 0 }, // Probably do at end of game
    numCreatedGames: 0, // done and tested
    numJoinedGames: 0, // done
    numFoundMafiaAsSheriff: 0,
    numVotedForMafiaWhenGood: 0,
    numVotedForMafiaWhenMafia: 0,
    numVotedForGoodWhenMafia: 0,
    numVotedForGoodWhenGood: 0,
    numPlayersKickedFromLobby: 0,
    mostNumPlayersInGame: 0,
    mostNumMafiasInGame: 0,
    mostNumDoctorsInGame: 0,
    mostNumSheriffsInGame: 0,
    numTimesKilledInFirstNight: 0,
    numTimesKilledInFirstDayVote: 0,
  };
};
