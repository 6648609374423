import {
  getLivingPlayers,
  didPlayersSeeInfo,
  changePlayersPropInDB,
} from "../../../utils/commonFunctions";
import { changeGameStatus } from "../../../utils/changeGameStatus";

// Admin checks if all players have seen info before moving game forward. Loop
export const adminDayReport = (context) => {
  const { playerList } = context.game;

  const livingPlayers = getLivingPlayers(playerList);

  // If all living players has seen info
  if (didPlayersSeeInfo(livingPlayers)) {
    console.log("All living players have seen info");
    changePlayersPropInDB(playerList, "seenInfo", false);

    // Set status to dayReportDone
    changeGameStatus("dayReportDone");
  }
  // If all living players haven't seen info
  else {
    console.log("All living players has NOT seen info");
  }
};
