import { getGameRef } from "../../utils/firebase";

export const cleanChoseProps = (doctorChose) => {
  const gameRef = getGameRef();

  console.log("doctorChose:", doctorChose);

  // Save doctor chose to old value first
  gameRef.child("oldDoctorChose").set(doctorChose);

  // Clean props
  gameRef.child("cityChose").set("");
  gameRef.child("mafiaChose").set("");
  gameRef.child("doctorChose").set("");
  gameRef.child("sheriffChose").set("");
};
