import { validatePlayerName } from "../../utils/commonFunctions";
import { createPlayerObject } from "../../utils/createObject";
import { gameListRef, increaseUserStat } from "../../utils/firebase";

export const onJoinGame = (state, updateState, context, routerHistory) => {
    const code = state.code;
    let name = state.name;
    let game = state.gameList[code];

    // Validate player name
    const valObj = validatePlayerName(name, game.playerList);
    if (!valObj.nameOk) {
      updateState({ nameError: valObj.error });
      return;
    }
    // If there were no name errors

    // Update player in main state and local storage
    context.updateState({ player: { name: state.name } });
    localStorage.setItem("player", state.name);

    // Create our player
    let player = createPlayerObject(
      state.name,
      false,
      context.player.uid,
      context.player.avatar
    );

    // Insert our player in game in db
    gameListRef.child(code + "/playerList/" + player.name).set(player);

    console.log("Joined game", code);

    // Start listen for game changes in db
    context.startGameListen(code);

    // Move on to GameLobby
    context.changePage("GameLobby");

    // If signed in, increase user stat
    if(typeof context.player.uid !== "undefined") increaseUserStat(context.player.uid, "numJoinedGames");

    // Clear URL (if someone joined with link invite)
    if (typeof routerHistory !== "undefined") routerHistory.push("/");
  };