import React, { Component } from "react";
import BigInfo from "../components/BigInfo";

export default class LastPage extends Component {
  whoWon = "ammo";
  buttons = [
    {
      text: "PLAY GAME",
      onClick: () => {}
    },
    {
      text: "HOW TO PLAY",
      onClick: () => {}
    }
  ];

  render() {
    return (
      <div>
        <BigInfo
          title="GAME OVER"
          buttons={this.buttons}
          text1="The city won"
        />
      </div>
    );
  }
}
