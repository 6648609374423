import React, { Component } from "react";
import DarkGoogleButton from "../../../images/btn_google_signin_dark_normal_web@2x.png";
import DarkGoogleButtonPressed from "../../../images/btn_google_signin_dark_pressed_web@2x.png";
import Button from "@material-ui/core/Button";
import * as firebase from "firebase";
import Grid from "@material-ui/core/Grid";

class SignInWithGoogleButton extends Component {
  state = {
    mouseDown: false,
    provider: new firebase.auth.GoogleAuthProvider(),
  };

  handleMouseDown = () => {
    console.log("Mouse down!");
    this.setState({ mouseDown: true });
  };

  handleMouseUp = () => {
    console.log("Mouse up!");
    this.setState({ mouseDown: false });
  };

  render() {
    return (
      <Grid item>
        <Button
          style={{
            width: "191px",
            height: "46px",
            backgroundImage: `url(${
              this.state.mouseDown ? DarkGoogleButtonPressed : DarkGoogleButton
            })`,
            backgroundSize: "cover",
          }}
          size="medium"
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onTouchStart={this.handleMouseDown}
          onTouchEnd={this.handleMouseUp}
          onClick={() =>
            firebase.auth().signInWithRedirect(this.state.provider)
          }
        >
          {" "}
        </Button>
      </Grid>
    );
  }
}

export default SignInWithGoogleButton;
