import { displayMafiaChose } from "../displayFunctions/displayMafiaChose";
import { adminMafiaDone } from "../adminFunctions/adminMafiaDone";

export const statusMafiaDone = (context, setState) => {
  console.log("Mafia is done!");

  const playerName = context.player.name;
  const player = context.game.playerList[playerName];
  const role = player.role;
  const seenInfo = player.seenInfo;
  const admin = player.admin;

  if (role === "mafia" && !seenInfo) displayMafiaChose(context, setState);

  if (admin) adminMafiaDone(context);
};
