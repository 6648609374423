import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Person from "@material-ui/icons/Person";
import Zoom from "@material-ui/core/Zoom";
import Avatar from "@material-ui/core/Avatar";
import { GameContext } from "../contexts/gameContext";
import Tooltip from "@material-ui/core/Tooltip";



class PlayerChip extends Component {
    static contextType = GameContext;

    render() { 
        const {player, onClick, colorFunction, disableFunction} = this.props;
        const disabled = disableFunction ? disableFunction(player) : false;
        const color = colorFunction ? colorFunction(player) : "primary";
        return ( 

          <Tooltip
                placement="top"
                title={player.votes}
                open={player.votes > 0}
                TransitionComponent={Zoom}
                key={`tooltip-${player.name}`}
              >
            <Zoom
              key={`zoom-${player.name}`}
              in={this.context.transition.in}
              timeout={this.context.transition.timeout}
              
            >
              <Grid item key={`item-${player.name}`}>
                <Chip
                  avatar={
                    player.avatar !== "" ? (
                      <Avatar
                        style={{ height: "36px", width: "36px" }}
                        src={player.avatar}
                      />
                    ) : null
                  }
                  icon={player.avatar === "" ? <Person /> : null}
                  label={player.name}
                  color={color}
                  disabled={disabled}
                  onClick={onClick? () => onClick(player.name, this.context): null}
                  key={`chip-${player.name}`}
                />
              </Grid>
            </Zoom>
            </Tooltip>

         );
    }
}
 
export default PlayerChip;