import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import { GameContext } from "../contexts/gameContext";

export default class GameCode extends Component {
  static contextType = GameContext;

  render() {
    return (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
        unmountOnExit
      >
        <Grid item>
          <Typography variant="h5">Game code</Typography>
          <Typography variant="h3" color="primary">
            {this.props.code}
          </Typography>
        </Grid>
      </Zoom>
    );
  }
}
