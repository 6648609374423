import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { GameContext } from "../../contexts/gameContext";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Zoom from "@material-ui/core/Zoom";

import RoleChip from "./RoleChip";
import BackButton from "./BackButton";
import BoolFacts from "./BoolFacts";
import VoteStats from "./VoteStats";

import { getVotingStatsFromPlayer } from "./getVotingStatsFromPlayer";
import { findTopPlayerFromStats } from "./findTopPlayerFromStats";
import { didTheyWin } from "./didTheyWin";
import { getBoolFacts } from "./getBoolFacts";

class IndividualPlayerStatsPage extends Component {
  static contextType = GameContext;

  constructor(props, context) {
    super(props, context);

    const player = this.context.game.playerList[
      localStorage.getItem("statsPlayer")
    ];

    this.state = {
      player: player,
      voteStats: {
        hate: { name: "", votes: 0, text: "NEMESIS" },
        love: { name: "", votes: 0, text: "FAVORITE" },
        suspect: { name: "", votes: 0, text: "TRUST ISSUES" },
      },
      boolFacts: [],
    };
  }

  componentDidMount() {
    // Make sure we can find the player object
    if (typeof this.state.player !== "undefined") {
      this.updateVoteStats();
      this.updateBoolFacts();
    }
    // If we didn't find the player object, go back
    else this.goBack();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.player !== this.state.player) {
      // Make sure we can find the player object
      if (typeof this.state.player !== "undefined") {
        this.updateVoteStats();
        this.updateBoolFacts();
      }
      // If we didn't find the player object, go back
      else this.goBack();
    }
  }

  updateVoteStats = () => {
    // Get voting stats from the player
    const votingStats = getVotingStatsFromPlayer(
      this.context.game.playerList,
      this.state.player.name
    );

    // Update state with the voting stats
    let newVoteStats = this.state.voteStats;
    Object.keys(this.state.voteStats).forEach((stat) => {
      return Object.assign(
        newVoteStats[stat],
        findTopPlayerFromStats(votingStats, stat)
      );
    });

    this.setState({
      voteStats: newVoteStats,
    });
  };

  updateBoolFacts() {
    const { player } = this.state;
    const { status } = this.context.game;
    this.setState({ boolFacts: getBoolFacts(player, status) });
  }

  goBack = () => {
    console.log("goBack() called");
    this.context.changePage("AllPlayersStatsPage");
  };

  changePlayer = (playerName) => {
    // Make sure player exists
    if (typeof this.context.game.playerList[playerName] !== "undefined") {
      this.context.changePage("IndividualPlayerStatsPage");
      setTimeout(() => {
        this.setState({ player: this.context.game.playerList[playerName] });
      }, this.context.transition.timeout);
    }
  };

  render() {
    // Make sure we can find the player object
    if (typeof this.state.player === "undefined") {
      this.goBack();
      return null;
    }

    const { name, role, alive, admin } = this.state.player;
    const { status } = this.context.game;

    return (
      <React.Fragment>
        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
        >
          <Grid item>
            <h1>{name}</h1>
          </Grid>
        </Zoom>

        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
        >
          <Grid item>
            <Table aria-label="simple table">
              <TableBody>
                <VoteStats
                  voteStats={this.state.voteStats}
                  changePlayer={this.changePlayer}
                />

                <TableRow>
                  <TableCell>ROLE</TableCell>
                  <TableCell align="center">
                    <RoleChip role={role} />
                  </TableCell>
                </TableRow>

                <BoolFacts boolFacts={this.state.boolFacts} />
              </TableBody>
            </Table>
          </Grid>
        </Zoom>

        <BackButton goBack={this.goBack} />
      </React.Fragment>
    );
  }
}

export default IndividualPlayerStatsPage;
