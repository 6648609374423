import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Person from "@material-ui/icons/Person";
import Zoom from "@material-ui/core/Zoom";
import Avatar from "@material-ui/core/Avatar";

import PlayerChip from "./PlayerChip";

import { GameContext } from "../contexts/gameContext";
import { createPopupObject } from "../utils/createObject";
import { getGameRef } from "../utils/firebase";

export default class PlayerList extends Component {
  static contextType = GameContext;

  

  render() {
    const {myPlayer, players, onClick, colorFunction, disableFunction} = this.props;

    return (
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          {this.props.players.map((player) => (
            <PlayerChip key={`playerchip-${player.name}`} myPlayer={myPlayer} player={player} onClick={onClick} colorFunction={colorFunction} disableFunction={disableFunction}/>
          ))}
        </Grid>
    );
  }
}
