import { createInfoBoxObject } from "../../../utils/createObject";
import { seenInfo } from "../seenInfo";

export const displayMafiaChose = (context, setState) => {
  let textObject = createInfoBoxObject();
  textObject.text1 = "The mafia chose";
  textObject.text2 = context.game.mafiaChose;
  textObject.color = "secondary";
  textObject.text3 = "to be killed";
  textObject.showButton = true;
  textObject.onClick = () => seenInfo(true, context, setState);

  setState({ textObject: textObject });
};
