import { createLabelInputButtonArr } from "../../../utils/createObject";
import {goToStep} from "./goToStep";

export const stepOne = (context, updateState) => {
    let layoutArr = createLabelInputButtonArr(2);

    layoutArr[0].label = "Create your own game?";
    layoutArr[0].buttonText = "CREATE";
    layoutArr[0].onClick = () => {
      goToStep("Two", context, updateState);
    };

    layoutArr[1].label = "Join a friend's game?";
    layoutArr[1].buttonText = "JOIN";
    layoutArr[1].onClick = () => {
      goToStep("Three", context, updateState);
    };

    updateState({ layoutArr: layoutArr });
  };