import { createInfoBoxObject } from "../../../utils/createObject";

export const displayNight = (setState) => {
  let textObject = createInfoBoxObject();
  textObject.text2 = "Go to sleep";
  textObject.color = "initial";
  textObject.text3 = "close your eyes";

  setState({ textObject: textObject });
};
