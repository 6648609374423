import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Zoom from "@material-ui/core/Zoom";
import { GameContext } from "../contexts/gameContext";

export default class InviteLink extends Component {
  static contextType = GameContext;

  handleCopy = () => {
    const el = document.createElement("textarea");
    el.value = window.location.href + "join/" + this.props.code;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  render() {
    return (
      <Zoom
        in={this.context.transition.in}
        timeout={this.context.transition.timeout}
        unmountOnExit
      >
        <Grid item>
          <Typography variant="h5">Invite link</Typography>
          <Typography variant="body1" color="primary">
            {window.location.href + "join/" + this.props.code}
          </Typography>
          <Button size="small" variant="outlined" onClick={this.handleCopy}>
            COPY LINK
          </Button>
        </Grid>
      </Zoom>
    );
  }
}
