import { createInfoBoxObject } from "../../../utils/createObject";
import { seenInfo } from "../seenInfo";

export const displayDoctorChose = (context, setState) => {
  let textObject = createInfoBoxObject();
  textObject.text1 = "The doctor chose";
  textObject.text2 = context.game.doctorChose;
  textObject.color = "primary";
  textObject.text3 = "to be healed";
  textObject.showButton = true;
  textObject.onClick = () => seenInfo(true, context, setState);

  setState({ textObject: textObject });
};
