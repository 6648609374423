import {
  getLivingPlayers,
  didPlayersSeeInfo,
  changePlayersPropInDB,
} from "../../../utils/commonFunctions";
import SleepMafia from "../../../audio/SleepMafia.mp3";
import { getGameRef } from "../../../utils/firebase";
import { playAudio } from "../../../utils/playAudio";

// Admin checks if all mafias have seen info before moving game forward. Loop
export const adminMafiaDone = (context) => {
  //const audio_sleepMafia = new Audio(SleepMafia);

  const livingMafias = getLivingPlayers(context.game.playerList, "mafia");

  // If all living mafias has seen info
  if (didPlayersSeeInfo(livingMafias)) {
    console.log("All living mafias has seen info");
    changePlayersPropInDB(context.game.playerList, "seenInfo", false);
    //audio_sleepMafia.play();
    playAudio("sleepMafia", context);

    // If game has a doctor
    if (context.game.roles.doctor) {
      console.log("The game has a doctor");
      // If the doctor is alive
      if (getLivingPlayers(context.game.playerList, "doctor").length > 0) {
        console.log("The doctor is alive");

        setTimeout(() => getGameRef().child("status").set("doctor"), 5000);
      }
      // If the doctor is dead
      else {
        // Fake doctor
        setTimeout(() => getGameRef().child("status").set("fakeDoctor"), 5000);
      }
    }
    // If game doesn't have doctor
    else {
      // If game has a sheriff
      if (context.game.roles.sheriff) {
        console.log("The game has a sheriff");
        // If the sheriff is alive
        if (getLivingPlayers(context.game.playerList, "sheriff").length > 0) {
          console.log("The sheriff is alive");

          setTimeout(() => getGameRef().child("status").set("sheriff"), 5000);
        }
        // If the sheriff is dead
        else {
          // Fake sheriff
          setTimeout(
            () => getGameRef().child("status").set("fakeSheriff"),
            5000
          );
        }
      }
      // If game doesn't have sheriff
      else {
        // Change status to nightReport
        setTimeout(() => getGameRef().child("status").set("nightReport"), 5000);
      }
    }
  }
  // If all living mafias haven't seen info
  else {
    console.log("All living mafias has NOT seen info");
  }
};
