import { createMuiTheme } from "@material-ui/core/styles";
import {mainTheme, mainProps} from "./mainTheme";

const gameLobbyTheme = createMuiTheme({
  overrides:{
    ...mainProps,
  MuiTooltip: {
    tooltip: {
      borderRadius: "20px",  
    },
    touch: {

    },
    tooltipPlacementTop: {
      margin: "7px",
      animation:
        "1000ms ease-in-out 0ms infinite normal none running testAnimate",
    },
  },},
  palette: {
    primary: {
      main: "#26547C",
      contrastText: "#fff"
    },
    secondary: {
      main: "#589D59",
      contrastText: "#fff"
    },
    type: "dark",
  },
});

export default gameLobbyTheme;
