import { createPopupObject } from "./createObject";
import {
  changePlayerPropInDB,
  clearAndReloadBrowser,
  leaveGame,
} from "./commonFunctions";
import { playAndPauseAllAudio } from "./playAudio";

// Popup router that eiter returns popup object or false
export const popupRouter = (step, fromDB, name, context) => {
  // Make sure it's actually a function
  if (typeof popupFunctions[step] === "function") {
    if (fromDB) changePlayerPropInDB(name, "popup", "");
    return popupFunctions[step](context);
  } else return false;
};

// Creates popupObject for the new admin
const newAdmin = () => {
  let popup = createPopupObject();
  popup.title = "New admin";
  popup.message =
    "Since the admin died, you are now the new admin. This means that the game will use your device to play sounds, so turn up the volume!";

  // Reload page to make sure the new admin does his job for current status
  popup.yesCB = () => window.location.reload();

  // Return popupObject
  return popup;
};

// Creates popupObject for admin that reloads page. Needed to allowSounds
const reload = (context) => {
  let popup = createPopupObject();
  popup.title = "Welcome back";
  popup.message = "Looks like your broswer reloaded, welcome back!";

  // Reload page to make sure the new admin does his job for current status
  popup.yesCB = () => playAndPauseAllAudio(context.updateState);

  // Return popupObject
  return popup;
};

// Creates popup object if you have been kicked from game
const kickedOut = () => {
  let popup = createPopupObject();
  popup.title = "Bye";
  popup.message = "You have been kicked out from the game.";
  popup.question = false;
  popup.yesCB = clearAndReloadBrowser;

  // Return popupObject
  return popup;
};

// Creates popup object asking if you want to leave the game
const leaving = () => {
  let popup = createPopupObject();
  popup.title = "Leave?";
  popup.message = "Are you sure you want to leave the game?";
  popup.question = true;
  popup.yesCB = leaveGame;

  // Return popupObject
  return popup;
};

// Library of popup functions
const popupFunctions = {
  newAdmin: newAdmin,
  kickedOut: kickedOut,
  leaving: leaving,
  reload: reload,
};
