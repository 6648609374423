import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import RoleInput from "../RoleInput";
import { GameContext } from "../../contexts/gameContext";
import { getGameRef } from "../../utils/firebase";
import { playAndPauseAllAudio } from "../../utils/playAudio";
import FormHelperText from '@material-ui/core/FormHelperText';
import { lockOrUnlockButton } from "./lockOrUnlockButton";
import { handleChange } from "./handleChange";
import { onStartGame } from "./onStartGame";


class AdminPanel extends Component {
  static contextType = GameContext;

  state = {
    roles: {
      mafia: 1,
      sheriff: 0,
      doctor: 0,
    },
    locked: true,
    oldPlayerList: this.context.game.playerList,
  };

  componentDidMount() {
    lockOrUnlockButton(this.context.game.playerList, this.updateState);
  }

  componentDidUpdate() {
    const playerList = this.context.game.playerList;

    // Check if start game button should be unlocked
    if (
      JSON.stringify(playerList) !== JSON.stringify(this.state.oldPlayerList)
    ) {
      lockOrUnlockButton(this.context.game.playerList, this.updateState);
      this.setState({ oldPlayerList: playerList });
    }
  }

updateState = (state) => {
  this.setState(state);
};


  

  render() {

    const {locked} = this.state;

    return (
      <Grid container item direction="column" alignItems="center" spacing={3}>

        <Zoom
          in={this.context.transition.in}
          timeout={this.context.transition.timeout}
        >
          <Grid item>
            <Typography variant="h5">Roles</Typography>
          </Grid>
        </Zoom>

        <Grid container direction="column" alignItems="center" spacing={2}>
          {Object.keys(this.state.roles).map((role) => {
            return (
              <RoleInput
                key={role}
                role={role}
                handleChange={(e) =>handleChange(e, this.updateState)}
                roles={this.state.roles}
              />
            );
          })}
        </Grid>

        <Zoom
          in={this.context.transition.in}
           timeout={this.context.transition.timeout}
        >
          <Grid item>              
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={locked}
              onClick={() => onStartGame(this.context, this.state.roles)}
            >
              Start Game
            </Button>
            <FormHelperText error>{locked? "You need atleast 3 players" : ""}</FormHelperText>
          </Grid>
        </Zoom>

      </Grid>
    );
  }
}

export default AdminPanel;
