import { createInfoBoxObject } from "../../../utils/createObject";
import { seenInfo } from "../seenInfo";
import { GoodNames, BadNames } from "../../../utils/nameLists";
import Sad from "../../../audio/Sad.mp3";
import Celebration from "../../../audio/Celebration.mp3";
import { changePlayerPropInDB } from "../../../utils/commonFunctions";
import { playAudio } from "../../../utils/playAudio";
import { increaseUserStat } from "../../../utils/firebase";

export const statusDayReport = (context, setState) => {
  console.log("It's dayReport time!");

  //const audio_sad = new Audio(Sad);
  //const audio_celebration = new Audio(Celebration);

  const game = context.game;
  const killed = game.cityChose !== "NONE";

  let textObject = createInfoBoxObject();
  textObject.text2 = killed ? game.cityChose : "NO PLAYER DIED";
  textObject.color = killed ? "secondary" : "primary";
  textObject.text3 = killed
    ? "was killed by the city"
    : "The city agreed to not kill anyone.";
  textObject.showButton = true;
  textObject.onClick = () => seenInfo(false, context, setState);

  const playerName = context.player.name;
  const admin = context.game.playerList[playerName].admin;

  // If admin and someone was killed, then kill them
  if (admin && killed) {
    if (GoodNames.includes(game.cityChose)) playAudio("sad", context);
    if (BadNames.includes(game.cityChose)) playAudio("celebration", context);
    changePlayerPropInDB(game.cityChose, "alive", false);
  }

  // If signed in, increase user stat
  if(typeof context.player.uid !== "undefined") increaseUserStat(context.player.uid, "numGameDaysPlayed");

  setState({ textObject: textObject });
};
